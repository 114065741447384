import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandMore } from "@material-ui/icons";

const userdropdownlist = [
  {
    primary: "Active Users",
    path: "/dashboard/users",
    exact: true
  },
  {
    primary: "Unsubscribed Users",
    path: "/dashboard/users/unsubscribers",
    exact: true
  },
  {
    primary: "Guest Users",
    path: "/dashboard/users/guests",
    exact: true
  }
  // {
  //   primary: "Loyalty Users",
  //   path: "/dashboard/users/loyalty",
  //   exact: true,
  // },
  // {
  //   primary: "Churn",
  //   path: "/dashboard/users/churn",
  //   exact: true
  // }
];

const concessionslist = [
  {
    primary: "All Concessions",
    path: "/dashboard/concessions",
    exact: true
  },
  {
    primary: "Combo Deals",
    path: "/dashboard/concessions/combo-deals",
    exact: true
  }
  // {
  //   primary: "Movies Stats",
  //   path: "/dashboard/movies/stats",
  //   exact: true
  // }
];

const moviedropdownlist = [
  {
    primary: "All Movies",
    path: "/dashboard/movies",
    exact: true
  },
  {
    primary: "Coming Soon",
    path: "/dashboard/movies/coming-soon",
    exact: true
  }
  // {
  //   primary: "Movies Stats",
  //   path: "/dashboard/movies/stats",
  //   exact: true
  // }
];

const admindropdownlist = [
  {
    primary: "Admin Users",
    path: "/dashboard/admin",
    exact: true
  }
  // {
  //   primary: "Access Control List",
  //   path: "/dashboard/admin/roles",
  //   exact: true
  // }
];

const dashboarddropdownlist = [
  {
    primary: "Analytics",
    path: "/dashboard/analytics",
    exact: true
  },
  // {
  //   primary: "Loyalty Stats",
  //   path: "/dashboard/analytics/loyalty-analytics",
  //   exact: true
  // },
  {
    primary: "Purchase History Analytics",
    path: "/dashboard/analytics/purchase-history-analytics",
    exact: true
  }
];

const notificationsdropdownlist = [
  {
    primary: "Notifications",
    path: "/dashboard/notifications",
    exact: true
  },
  {
    primary: "Send Notification",
    path: "/dashboard/notifications/send",
    exact: true
  }
];

const MainItem = ({ icon, exact, path, primary, dropdown, setLocation }) => {
  const location = useLocation();
  const Icon = icon;

  const colorIcons = match => !!match;
  return (
    <>
      <NavLink activeClassName="active" exact={exact} to={path}>
        <ListItem button>
          <ListItemIcon>
            <Icon
              style={{
                color: location.pathname.includes(path) ? "#A233E2" : "#fff"
              }}
            />
          </ListItemIcon>
          <ListItemText primary={primary} />
          {dropdown && <ExpandMore />}
        </ListItem>
      </NavLink>
      {path === "/dashboard/users" &&
      location.pathname.includes("/dashboard/users")
        ? userdropdownlist.map(({ primary, path, exact }, i) => {
            return (
              <div key={i} className="inactive-user-dropdown">
                <NavLink
                  activeClassName="active-user-dropdown"
                  exact={exact}
                  to={path}
                  isActive={colorIcons}
                >
                  <ListItem button>
                    <ListItemText primary={primary} />
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        : path === "/dashboard/movies" &&
          location.pathname.includes("/dashboard/movies")
        ? moviedropdownlist.map(({ primary, path, exact }, i) => {
            return (
              <div key={i} className="inactive-user-dropdown">
                <NavLink
                  activeClassName="active-user-dropdown"
                  exact={exact}
                  to={path}
                  isActive={colorIcons}
                >
                  <ListItem button>
                    <ListItemText primary={primary} />
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        : path === "/dashboard/concessions" &&
          location.pathname.includes("/dashboard/concessions")
        ? concessionslist.map(({ primary, path, exact }, i) => {
            return (
              <div key={i} className="inactive-user-dropdown">
                <NavLink
                  activeClassName="active-user-dropdown"
                  exact={exact}
                  to={path}
                  isActive={colorIcons}
                >
                  <ListItem button>
                    <ListItemText primary={primary} />
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        : path === "/dashboard/admin" &&
          location.pathname.includes("/dashboard/admin")
        ? admindropdownlist.map(({ primary, path, exact }, i) => {
            return (
              <div key={i} className="inactive-user-dropdown">
                <NavLink
                  activeClassName="active-user-dropdown"
                  exact={exact}
                  to={path}
                  isActive={colorIcons}
                >
                  <ListItem button>
                    <ListItemText primary={primary} />
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        : path === "/dashboard/analytics" &&
          location.pathname.includes("/dashboard/analytics")
        ? dashboarddropdownlist.map(({ primary, path, exact }, i) => {
            return (
              <div key={i} className="inactive-user-dropdown">
                <NavLink
                  activeClassName="active-user-dropdown"
                  exact={exact}
                  to={path}
                  isActive={colorIcons}
                >
                  <ListItem button>
                    <ListItemText primary={primary} />
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        : path === "/dashboard/notifications" &&
          location.pathname.includes("/dashboard/notifications")
        ? notificationsdropdownlist.map(({ primary, path, exact }, i) => {
            return (
              <div key={i} className="inactive-user-dropdown">
                <NavLink
                  activeClassName="active-user-dropdown"
                  exact={exact}
                  to={path}
                  isActive={colorIcons}
                >
                  <ListItem button>
                    <ListItemText primary={primary} />
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        : null}
    </>
  );
};

export default MainItem;
