import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import api from "../../../api/payments";
import { Alert } from "@material-ui/lab";
import {
  dateFromISO,
  debounce,
  getRequestError
} from "../../../utils/functions";
import { useCallback } from "react";
import DropDown from "../../../components/Inputs/Dropdown";
import useStyles from "./styles";
import Vectors from "./vectors";
import { useHistory } from "react-router-dom";

const dummyPayments = [
  {
    id: 1,
    isConcession: true,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 2,
    isConcession: false,
    isMerchandise: false,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 3,
    isConcession: true,
    isMerchandise: false,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 4,
    isConcession: true,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 5,
    isConcession: true,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 6,
    isConcession: true,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 7,
    isConcession: false,
    isMerchandise: false,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 8,
    isConcession: true,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 9,
    isConcession: true,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  },
  {
    id: 10,
    isConcession: false,
    isMerchandise: true,
    timeAndDate: "April, 15 2025 | 8:00pm ",
    location:
      "Landmark Centre. Water Corporation Drive #Plot 2 & 3 Annex, LA 106104",
    name: "Barbie Premiere"
  }
];

const AllEvents = () => {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [events, setEvents] = useState([]);
  const [error, setError] = useState({ isError: false, message: "" });
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const { jwt } = useSelector(state => state.user.user);

  const history = useHistory();

  const classes = useStyles();

  const handleEventsData = useCallback(async () => {
    // try {
    //   let resData;
    //   if (searchInput) {
    //     resData = await api.searchPayments(`Bearer ${jwt}`, searchInput);
    //   } else {
    //     resData = await api.fetchPayments(`Bearer ${jwt}`, userType, page + 1);
    //   }
    //   const { totalCount = 0 } = resData.data.data;
    //   const paymentsData = searchInput
    //     ? resData?.data?.data ?? []
    //     : resData?.data?.data?.payment_logs ?? [];
    //   setLoading(false);
    //   const mappedPaymentData = paymentsData?.map((item, index) => {
    //     const { hour, minute, second, day, month, year } = dateFromISO(
    //       item?.createdAt ?? ""
    //     );
    //     return {
    //       date: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
    //       bookingID: item?.bookingId ?? "",
    //       userID: item?.data?.meta?.userId ?? "_",
    //       id: item?.data?.id ?? index,
    //       email: item?.data?.meta?.email ?? "",
    //       amount: `₦${item?.data?.meta?.amount?.toLocaleString()}` ?? "",
    //       paymentType: item?.purchaseType ?? "",
    //       status: item?.status ?? "",
    //     };
    //   });
    //   setEvents(mappedPaymentData);
    //   setTotalData(totalCount);
    // } catch (e) {
    //   setError({ isError: false, message: getRequestError(e) });
    // }
  }, [jwt, page, searchInput]);

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    handleEventsData();
  }, [page, jwt, searchInput, handleEventsData]);

  const columns = [
    {
      field: "name",
      headerName: "Event Name",
      width: 280
    },
    { field: "location", headerName: "Location", width: 260 },
    {
      field: "timeAndDate",
      headerName: "Event Time & Date ",
      width: 192
    },
    {
      field: "isConcession",
      headerName: "Concession ",
      width: 146,
      renderCell: params => {
        return (
          <p
            className={`${classes.status} ${
              params.row.isConcession ? classes.success : classes.error
            }`}
          >
            <span className={classes.dot} />
            <span>
              {params.row.isConcession ? "Available" : "Not available"}
            </span>
          </p>
        );
      }
    },
    {
      field: "isMerchandise",
      headerName: "Merchandise",
      width: 146,
      renderCell: params => {
        return (
          <p
            className={`${classes.status} ${
              params.row.isMerchandise ? classes.success : classes.error
            }`}
          >
            <span className={classes.dot} />
            <span>
              {params.row.isMerchandise ? "Available" : "Not available"}
            </span>
          </p>
        );
      }
    },
    {
      field: "id",
      headerName: " ",
      width: 120,
      renderCell: params => {
        return (
          <div className={classes.tableActionCol}>
            <button>
              <Vectors.Delete />
            </button>
            <button>
              <Vectors.Edit />
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <>
        <div className={classes.header}>
          <div className={classes.search}>
            <PaperedInput
              onChange={e => {
                e.persist();
                debounce(() => setSearchInput(e.target.value), 2000);
              }}
              placeholder="Search for Event"
            />
          </div>
          <div className={classes.rightAction}>
            <div className={classes.addBtn}>
              <Button
                onClick={() => {
                  history.push("/dashboard/event-cinema/create");
                }}
                style={{ width: "100%" }}
                variant="outlined"
                color="primary"
              >
                + ADD EVENT{" "}
              </Button>
            </div>
            <div className={classes.downloadBtn}>
              <CSVLink data={dummyPayments} filename={"payment-logs.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={dummyPayments}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              page={searchInput ? undefined : page}
              autoHeight={true}
              rowCount={searchInput ? undefined : totalData}
              onPageChange={
                searchInput
                  ? undefined
                  : page => {
                      setPage(page);
                    }
              }
              //   paginationMode={searchInput ? "client" : "server"}
              // loading={loading}
              rowsPerPageOptions={[10]}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default AllEvents;
