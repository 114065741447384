import React, { useEffect, useState } from "react";
import EventForm from "./EventForm";
import { connect } from "react-redux";

const CreateEvent = ({ user }) => {
  return (
    <EventForm
      jwt={user.jwt}
      event={{
        title: "",
        description: "",
        category: "",
        movies: [],
        date: "",
        time: "",
        price: "",
        tickets: ""
      }}
      formTitle={"Create an Event"}
      onSubmit={() => {}}
      type={"add"}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(CreateEvent);
