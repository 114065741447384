import api from "./instance";

export default {
  fetchCinemas: token =>
    api
      .get(`/cinema`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
