import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import useStyles from "./styles";
import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import movieApi from "../../../api/movie-entry-api";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import moment from "moment";
import empty from "../../../assets/images/empty-concessions.png";
import { useHistory } from "react-router-dom";

const EventForm = ({ event, onSubmit, formTitle, jwt, type }) => {
  const [title, setTitle] = useState(event.title);
  const [description, setDescription] = useState(event.description);
  const [category, setCategory] = useState(event.category);
  const [movies, setMovies] = useState(event.movies);
  const [date, setDate] = useState(event.date);
  const [time, setTime] = useState(event.time);
  const [price, setPrice] = useState(event.price);
  const [tickets, setTickets] = useState(event.tickets);
  const [searchedMovie, setSearchedMovie] = useState([]);
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const classes = useStyles();

  const handleMovieSearch = async search => {
    try {
      const res = await movieApi.searchMovieFHDB(search, jwt);
      if (res.data.data) {
        setSearchedMovie(
          res?.data?.data?.map(item => ({
            id: item?.id,
            rosettaId: item?.rosetta_movie_id,
            movieTitle: item?.movie_title,
            label: item?.movie_title,
            description: item?.description,
            youtubeTrailerURL: item?.youtube_trailer,
            img: item?.small_poster ?? empty,
            webMedia: item?.big_backround,
            mobileMedia: item?.small_background,
            thumbnailImage: item?.small_poster
          })) ?? []
        );
      } else {
        setSearchedMovie([]);
      }
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
    }
  };

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  console.log(time, date);

  return (
    <div>
      <h1 className={classes.formHeading}>{formTitle}</h1>
      <form
        id="event-form"
        className={classes.form}
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <h2 className={classes.formSubHeading}>OVERVIEW</h2>
        <div className={classes.formPair}>
          <TextField
            name="eventTitle"
            onChange={e =>
              handleChange(e, setTitle, value => value.length <= 100)
            }
            value={title}
            variant="outlined"
            label="Event Name"
            className={classes.formField}
            required
          />
          <FormControl variant="outlined" className={classes.formField}>
            <InputLabel> Category </InputLabel>
            <Select
              value={category}
              onChange={e => handleChange(e, setCategory)}
              onBlur={e => handleChange(e, setCategory)}
              name="eventCategory"
            >
              {[
                "Movie Premieres",
                "Watch Parties",
                "Q&As With Filmmakers,",
                " Themed Screening",
                "Extras"
              ].map((name, i) => {
                return (
                  <MenuItem key={i} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <TextField
          name="eventDescription"
          onChange={e => handleChange(e, setDescription)}
          value={description}
          multiline
          rows={3}
          variant="outlined"
          label="Description"
          style={{ flex: 1 }}
          required
        />
        <div className={classes.formPair}>
          <TextField
            id="eventDate"
            name="eventDate"
            label="Date"
            type="date"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={e => handleChange(e, setDate)}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            className={classes.formField}
            required
          />
          <TextField
            id="eventTime"
            name="eventTime"
            label="Time"
            type="time"
            value={time}
            onChange={e => handleChange(e, setTime)}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            className={classes.formField}
            required
          />
        </div>

        <div className={classes.formPair}>
          <TextField
            name="eventPrice"
            prefix="₦"
            onChange={e =>
              handleChange(e, setPrice, value => !isNaN(value.length))
            }
            value={price}
            variant="outlined"
            label="Ticket Price"
            className={classes.formField}
            required
          />
          <TextField
            name="eventTickets"
            onChange={e =>
              handleChange(e, setTickets, value => !isNaN(value.length))
            }
            value={tickets}
            variant="outlined"
            label="Ticket Available"
            className={classes.formField}
            required
          />
        </div>
        <div className={classes.actionBtnCont}>
          <Button
            onClick={() => {
              history.push("/dashboard/event-cinema");
            }}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            CANCEL
          </Button>
          <Button
            form="event-form"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "add"
              ? loading
                ? "ADDING..."
                : "SAVE EVENT"
              : loading
              ? "EDITING"
              : "EDIT EVENT"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EventForm;
