import api from "./instance";

export default {
  fetchSubscriptions: token =>
    api
      .get("/subscriptions", {
        headers: { Authorization: token }
      })
      .then(res => res)
};
