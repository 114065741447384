import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px"
  },
  search: {
    maxWidth: "487px",
    width: "60%"
  },
  rightAction: {
    display: "flex",
    gap: "16px",
    width: "35%",
    alignItems: "center"
  },
  addBtn: {
    maxWidth: "197px",
    width: "50%"
  },
  downloadBtn: {
    maxWidth: "226px",
    width: "50%"
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    display: "inline-block"
  },
  status: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    borderRadius: "16px",
    padding: "2px 6px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#FFFFFF",
    "& span": {
      lineHeight: "1.5"
    }
  },
  success: {
    backgroundColor: "#027A48"
  },
  error: {
    backgroundColor: "#B42318"
  },
  tableActionCol: {
    display: "flex",
    alignItems: "center"
  },
  formHeading: {
    fontSize: "40px",
    fontWeight: 500,
    color: "#FFFFFF",
    lineHeight: "46.88px",
    marginBottom: "32px"
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "22px"
  },
  formPair: {
    display: "flex",
    gap: "22px",
    width: "100%"
  },
  formSubHeading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#606063",
    lineHeight: "42.19px"
  },
  formField: {
    width: "100%"
  },
  actionBtnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "14px",
    "& button": {
      width: "160px",
      height: "44px"
    }
  }
}));

export default useStyles;
