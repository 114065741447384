import moment from "moment";

export const getDays = () => {
  const days = new Array(31).fill(0).map(({}, index) => {
    const day = ("0" + (index + 1).toString()).slice(-2);

    return {
      label: day,
      value: day
    };
  });

  return days;
};

export const generateFormattedDates = numberOfDays => {
  const today = moment();
  const dates = [];

  for (let i = 0; i < numberOfDays; i++) {
    const currentDate = today.clone().add(i, "days");
    dates.push({
      name: i === 0 ? "Today" : currentDate.format("MMM, ddd D"),
      value: currentDate.format("YYYY-MM-DD")
    });
  }

  return dates;
};

export function generateCubeHourlyTimes() {
  const startTime = moment().set({ hour: 9, minute: 0, second: 0 });
  const endTime = moment().set({ hour: 21, minute: 0, second: 0 });
  const hourlyTimes = [];

  let currentTime = startTime.clone();

  while (currentTime.isSameOrBefore(endTime)) {
    const name = currentTime.format("h:mm A");
    const value = currentTime.format("HH:mm:ss");

    hourlyTimes.push({ name, value });
    currentTime.add(1, "hour");
  }

  return hourlyTimes;
}

export function areArraysEqual(arr1, arr2) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays to ensure the order of elements doesn't affect the comparison
  arr1.sort((a, b) => JSON.stringify(a) - JSON.stringify(b));
  arr2.sort((a, b) => JSON.stringify(a) - JSON.stringify(b));

  // Iterate through the arrays and compare each element
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }

  return true;
}

export const getMonths = () => {
  const months = new Array(12).fill(0).map(({}, index) => ({
    label: moment(index + 1, "M").format("MMMM"),
    value: moment(index + 1, "M").format("MM")
  }));

  return months;
};

export const getYears = () => {
  const years = new Array(99).fill(0).map(({}, index) => {
    const year = (moment().format("YYYY") - index).toString();

    return {
      label: year,
      value: year
    };
  });

  return years;
};

export const getRequestError = error => {
  const { response } = error;
  if (response && response.data.statusCode === 401) {
    localStorage.removeItem("persist:root");
    // window.location.href = "/";
    return "";
  } else if (response && response.data.errors && response.data.errors[0]) {
    return response.data.errors[0].message;
  } else if (response && response.data.message) {
    return response.data.message;
  }
  return "There might be a problem with your internet connection. Please check and try again.";
};

export const applyEllipsis = (text, limit) => {
  return text && text.length > limit ? `${text.substring(0, limit)}...` : text;
};

export const dateFromISO = date => {
  const year = date.slice(0, 4),
    month = date.slice(5, 7),
    day = date.slice(8, 10),
    hour = date.slice(11, 13),
    minute = date.slice(14, 16),
    second = date.slice(17, 19);
  return { year, month, day, hour, minute, second };
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const removeItemOnce = (arr, value) => {
  console.log(arr, value);
  const index = arr.indexOf(value);
  console.log(index);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const debounce = (cb, time) => {
  let debounce;
  clearTimeout(debounce);
  debounce = setTimeout(() => {
    cb();
  }, time);
};

export const slugify = text => {
  const textEdit = text ? text : "";
  return (
    textEdit
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
};

export const getStringLengthinPixel = (typography, string) => {
  const div = document.createElement("div");
  div.textContent = string;
  const id = `${string + Math.random()}`;
  div.id = id;
  div.style = `${typography}; width: fit-content; position: absolute, top: 1000000000000000000000000000000; left: 1000000000000000000000000`;
  document.body.appendChild(div);
  const textWidth = document.getElementById(id).clientWidth;
  document.body.removeChild(div);

  return textWidth;
};
