import React, { useEffect, useState } from "react";
import ManagePromotion from "./ManagePromotion";
import api from "../../../api/new-promotions";
import types from "../../../redux/types";
import { connect, useDispatch } from "react-redux";
import { getRequestError } from "../../../utils/functions";
import { useParams, useHistory } from "react-router-dom";

const EditPromotion = ({ user }) => {
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;
  const [data, setData] = useState(null);

  const history = useHistory();

  const params = useParams();
  console.log(params);

  const handleEdit = async payload => {
    return api
      .editPromotion(`Bearer ${user.jwt}`, payload, params.id)
      .then(res => {
        //navigate to promotions page
        history.goBack();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  useEffect(() => {
    api
      .getPromotion(`Bearer ${user.jwt}`, params.id)
      .then(res => {
        const data = res.data.data;
        setData({
          mobileMedia: data?.mobile_media ?? "",
          webMedia: data?.web_media ?? "",
          thumbnailMedia: data?.thumbnail ?? "",
          title: data?.title ?? "",
          primaryCTA: data?.primaryCTA ?? "",
          secondaryCTA: data?.secondaryCTA ?? "",
          primaryURL: data?.primaryCTAUrl ?? "",
          secondaryURL: data?.secondaryCTAUrl ?? "",
          description: data?.description ?? "",
          isActive: data?.isActive ?? false,
          isVisibleOnHomepage: data?.isVisibleOnHomepage ?? false
        });
      })
      .catch(err => {
        return err;
      });
  }, [params.id]);

  return data ? (
    <ManagePromotion
      handleAction={handleEdit}
      data={data}
      formTitle="Add a Promotion"
    />
  ) : null;
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(EditPromotion);
