import api from "./instance";

export default {
  getAllMerchandise: token => {
    return api
      .get(`/merchandises`, {
        headers: { Authorization: token }
      })
      .then(res => res);
  },
  getSingleMerch: (id, token) => {
    return api
      .get(`/merchandises/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res);
  }
};
