import React from "react";
import ManagePromotion from "./ManagePromotion";
import api from "../../../api/new-promotions";
import types from "../../../redux/types";
import { connect, useDispatch } from "react-redux";
import { getRequestError } from "../../../utils/functions";
import { useHistory } from "react-router-dom";

const AddPromotion = ({ user }) => {
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const handleAdd = async payload => {
    return api
      .addPromotion(`Bearer ${user.jwt}`, payload)
      .then(res => {
        //navigate to promotions page
        history.goBack();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  return (
    <ManagePromotion
      handleAction={handleAdd}
      destinationUrl={""}
      mobileMedia={""}
      webMedia={""}
      thumbnailMedia={""}
      data={{
        title: "",
        description: "",
        webMedia: "",
        mobileMedia: "",
        thumbnailMedia: "",
        primaryCTA: "",
        secondaryCTA: "",
        primaryURL: "",
        secondaryURL: "",
        isActive: false,
        isVisibleOnHomepage: false
      }}
      formTitle="Add a Promotion"
      type={"add"}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(AddPromotion);
