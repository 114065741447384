import api from "./instance";

export default {
  getCastMembers: (token, page, search) =>
    api
      .get(`/cast-members?page=${page}&perPage=10&search=${search}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  getShortLink: link =>
    api
      .post(
        `https://api-ssl.bitly.com/v4/shorten`,
        {
          long_url: link,
          domain: "bit.ly"
        },
        {
          headers: {
            Authorization: `Bearer 4417d3dda30223e47adde875dc53506dbdfde2a8`,
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => res),
  addCastMember: (token, payload) =>
    api
      .post("/cast-members", payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editCastMember: (token, payload, id) =>
    api
      .patch(`/cast-members/${id}`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  removeCastMember: (token, id) =>
    api
      .delete(`/cast-members/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
