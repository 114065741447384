import types from "../types";
import api from "../../api/auth-api";

const { ADMIN_LOGIN, ADMIN_LOGOUT, ALERT_OPEN } = types;

export const login = user => ({
  type: ADMIN_LOGIN,
  user
});

export const logout = () => ({
  type: ADMIN_LOGOUT
});

// export const adminLogin = user => dispatch =>
//   api.login(user).then(res => {
//     const resData = res.data;
//     const userData = {
//       info: { ...resData.data.user },
//       jwt: resData.data.token
//     };
//     if (userData.info.userType) {
//       dispatch(login(userData));
//       localStorage.setItem("filmhouseAdminJWT", res.data.jwt);
//       localStorage.setItem("filmhouseAdminUser", JSON.stringify(res.data.user));
//       return res;
//     } else {
//       const err = new Error("No permissions");
//       throw err;
//     }
//   });

export const adminLogin = user => dispatch =>
  api.login(user).then(res => {
    const resData = res.data;
    api
      .getAdmin(`Bearer ${resData?.data?.tokens?.access_token}`)
      .then(newRes => {
        const userResData = newRes?.data?.data;
        const loggedInuser = {
          id: userResData?.id,
          email: userResData?.email,
          userType: userResData?.userType,
          firstName: userResData?.firstName,
          lastName: userResData?.lastName,
          profileImage: userResData?.profileImage,
          mobile: userResData?.mobile
        };
        const userData = {
          // info: { ...resData.data.user },
          info: { ...loggedInuser },
          jwt: resData.data.tokens.access_token,
          refreshToken: resData.data.tokens.refresh_token
        };
        if (
          userData.info.userType &&
          userData.info.userType?.toLowerCase()?.includes("admin")
        ) {
          dispatch(login(userData));
          // localStorage.setItem("filmhouseAdminJWT", res.data.jwt);
          localStorage.setItem(
            "filmhouseAdminJWT",
            resData.data.tokens.access_token
          );
          localStorage.setItem(
            "filmhouseAdminRefreshToken",
            resData.data.tokens.refresh_token
          );
          // localStorage.setItem("filmhouseAdminUser", JSON.stringify(res.data.user));
          localStorage.setItem(
            "filmhouseAdminUser",
            JSON.stringify(loggedInuser)
          );
          return res;
        } else {
          const err = new Error("No permissions");
          throw err;
        }
      });
  });

export const adminLogout = token => dispatch => dispatch(logout());
// api
//   .logout(token)
//   .then(() => dispatch(logout()))
//   .catch(() =>
//     dispatch({
//       type: ALERT_OPEN,
//       payload: {
//         severity: "error",
//         message: "Something went wrong, try again"
//       }
//     })
//   );

export const passResetLogin = tokenated => dispatch =>
  api.resetPasswordComp(tokenated).then(res => {
    const userData = { ...res.data.user, jwt: res.data.jwt };
    dispatch(login(userData));
    return res;
  });
