import api from "./instance";

export default {
  getCinemaMovies: (cinemaId, startDate, endDate, token) =>
    api
      .get(
        `/movie/new-list?cinema_id=${cinemaId}&start_date=${startDate}&end_date=${endDate}&platform=WEB`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getCubes: () => api.get(`/cubes`).then(res => res),
  getSlots: (cube_id, date) =>
    api
      .get(`cubes/bookings/available-slots?date=${date}&cube_id=${cube_id}`)
      .then(res => res),
  getBookings: (token, cubeId, status) =>
    api
      .get(
        `/cubes/bookings?${cubeId ? `cube_id=${cubeId}` : ""}${
          status ? `&booking_status=${status}` : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  createBookings: (token, payload) =>
    api
      .post("/cubes/bookings", payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  rescheduleBookings: (token, payload, id) =>
    api
      .patch(`/cubes/bookings/${id}/reschedule`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  editBookings: (token, payload, id) =>
    api
      .patch(`/cubes/bookings/${id}`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  editStatus: async (token, id, payload) => {
    const res = await api.patch(`/cubes/bookings/${id}/status`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return res;
  }
};
