import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { Typography, TextField, Box, Grid } from "@material-ui/core";
import urls from "../../../api/urls";
import movieApi from "../../../api/movie-entry-api";
import fileApi from "../../../api/file";
import FormValidator from "./../../../utils/FormValidator";
import { Autocomplete } from "@material-ui/lab";
import empty from "../../../assets/images/empty-concessions.png";
import { useDispatch } from "react-redux";
import { debounce, getRequestError } from "../../../utils/functions";
import types from "../../../redux/types";
import Close from "../../../vectors/Close";
const { movieDB } = urls;

const AddHeroBanner = ({ open, handleClose, add, edit, jwt, type, data }) => {
  const { castsDialog, pair, errorMsgStyle, characterField } = useStyles();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [rosettaMovieID, setRosettaMovieID] = useState("");
  const [movieID, setMovieID] = useState("");
  const [loading, setLoading] = useState();
  const [webImage, setWebImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [isWebUploading, setIsWebUploading] = useState(false);
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [ismobileUploading, setIsMobileUploading] = useState(false);
  const [trailerUrl, setTrailerURL] = useState("");
  const [searchedMovie, setSearchedMovie] = useState([]);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleMovieSearch = async search => {
    try {
      const res = await movieApi.searchMovieFHDB(search, jwt);
      if (res.data.data) {
        setSearchedMovie(
          res?.data?.data?.map(item => ({
            id: item?.id,
            rosettaId: item?.rosetta_movie_id,
            movieTitle: item?.movie_title,
            label: item?.movie_title,
            description: item?.description,
            youtubeTrailerURL: item?.youtube_trailer,
            img: item?.small_poster ?? empty,
            webImage: item?.big_backround,
            mobileImage: item?.small_background,
            thumbnailImage: item?.small_poster
          })) ?? []
        );
      } else {
        setSearchedMovie([]);
      }
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
    }
  };

  const validator = new FormValidator([
    {
      field: "title",
      method: "isEmpty",
      validWhen: false,
      message: "Title cannot be empty."
    },
    {
      field: "description",
      method: "isLength",
      args: [{ min: 1, max: 500 }],
      validWhen: true,
      message: "Description must be between 1 - 300 chars."
    },
    {
      field: "rosettaMovieID",
      method: "isEmpty",
      validWhen: false,
      message: "Movie ID cannot be empty"
    },
    {
      field: "brightcoveId",
      method: "isURL",
      validWhen: true,
      message: "Trailer URL must be a valid URL."
    }
  ]);
  const [validation, setValidation] = useState(validator.reset());

  useEffect(() => {
    if (open && type === "add") {
      setTitle("");
      setDescription("");
      setMovieID("");
      setTrailerURL("");
      setThumbnailImage("");
      setWebImage("");
      setMobileImage("");
    }
    setLoading(false);
  }, [open, type]);

  useEffect(() => {
    if (data) {
      setDescription(data.description);
      setMovieID(data.movieID);
      setRosettaMovieID(data.rosettaId);
      setTrailerURL(data.youtubeTrailerURL);
      setThumbnailImage(data.thumbnailImage);
      setWebImage(data.webImage);
      setMobileImage(data.mobileImage);
      setTitle(data.title);
    }
  }, [data]);

  const extHandleee = () => {
    if (type === "add") {
      setTitle("");
      setDescription("");
      setMovieID("");
      setRosettaMovieID("");
      setTrailerURL("");
      setThumbnailImage("");
      setWebImage("");
      setMobileImage("");
    }
    handleClose();
  };

  const uploadImage = async (type, file) => {
    const payload = new FormData();
    payload.append("file", file);

    if (type === "thumbnail") {
      setIsThumbnailUploading(true);
    } else if (type === "mobile") {
      setIsMobileUploading(true);
    } else {
      setIsWebUploading(true);
    }

    try {
      const response = await fileApi.uploadImageWithFile(payload);
      const image = response.data.data;
      console.log(response.data.data);

      if (type === "thumbnail") {
        setThumbnailImage(image);
        setIsThumbnailUploading(false);
      } else if (type === "mobile") {
        setMobileImage(image);
        setIsMobileUploading(false);
      } else {
        setWebImage(image);
        setIsWebUploading(false);
      }
    } catch (error) {
      console.log(error);
      setIsThumbnailUploading(false);
      setIsWebUploading(false);
      setIsMobileUploading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(error)
        }
      });
    }
  };

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let heroBannerPayload;
    if (type === "add") {
      heroBannerPayload = {
        movie_title: title,
        description,
        rosetta_movie_id: rosettaMovieID,
        movie_id: movieID,
        youtube_trailer_link: trailerUrl,
        web_image: webImage,
        mobile_image: mobileImage,
        thumbnail: thumbnailImage
      };
    } else {
      heroBannerPayload = {
        title,
        description,
        rosetta_movie_id: rosettaMovieID,
        destination_id: rosettaMovieID,
        movie_db_info_id: movieID,
        youtube_trailer: trailerUrl,
        web_image: webImage,
        mobile_image: mobileImage,
        thumbnail: thumbnailImage
      };
    }

    const heroBannerValidation = {
      title,
      description,
      rosettaMovieID,
      brightcoveId: trailerUrl
    };

    const _validation = validator.validate(heroBannerValidation);
    if (_validation.isValid) {
      setValidation(_validation);
      if (!(webImage || mobileImage)) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Add an image for either web or mobile"
          }
        });
        return;
      }
      if (webImage && !thumbnailImage) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Add a thumbnail image for web"
          }
        });
        return;
      }
      setLoading(true);
      if (type === "add") {
        await add(heroBannerPayload).then(() => {
          setLoading(false);
        });
      } else if (type === "edit") {
        await edit(heroBannerPayload).then(() => {
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
      setValidation(_validation);
    }
  };

  const errorMsg = propertyName => {
    return (
      !validation[propertyName].isValid && (
        <p className={errorMsgStyle}>{validation[propertyName].message}</p>
      )
    );
  };

  return (
    <Dialog
      fullWidth="xl"
      open={open}
      onClose={extHandleee}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={castsDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">
            {type === "add" ? "Add" : "Edit"} Banner
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form
              id="form-test"
              style={{ marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={searchedMovie}
                getOptionLabel={option => option.label}
                onChange={(_, value) => {
                  if (value) {
                    setDescription(value.description);
                    setMovieID(value.id);
                    setRosettaMovieID(value.rosettaId);
                    setTrailerURL(value.youtubeTrailerURL);
                    setThumbnailImage(value.thumbnailImage);
                    setWebImage(value.webImage);
                    setMobileImage(value.mobileImage);
                    setTitle(value.movieTitle);
                  }
                }}
                style={{ flex: 1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Movie Title"
                    name="title"
                    value={title}
                    variant="outlined"
                    onChange={e => {
                      // handleChange(e, setTitle)
                      e.persist();
                      if (e.target.value) {
                        debounce(() => handleMovieSearch(e.target.value), 1000);
                        // let debounce;
                        // clearTimeout(debounce);
                        // debounce = setTimeout(() => {
                        //   // console.log(e?.target?.value);
                        //   handleMovieSearch(e.target.value);
                        // }, 1000);
                      }
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Grid spacing={1} alignItems="center" component="li">
                    <Grid item>
                      <img
                        loading="lazy"
                        width="40"
                        height="40"
                        src={props?.img}
                        srcSet={props?.img}
                        alt=""
                      />
                    </Grid>
                    <Grid item>
                      <span>{props?.label ?? ""}</span>
                    </Grid>
                  </Grid>
                )}
              />
              {/* </div> */}
              {errorMsg("title")}
              <div className={pair}>
                <TextField
                  name="description"
                  onChange={e => handleChange(e, setDescription)}
                  value={description}
                  multiline
                  rows={3}
                  variant="outlined"
                  label="Description"
                  style={{ flex: 1 }}
                />
              </div>
              {errorMsg("description")}
              <div className={pair}>
                <TextField
                  name="rosettaMovieID"
                  onChange={e => handleChange(e, setMovieID)}
                  value={rosettaMovieID}
                  variant="outlined"
                  label="Movie ID"
                  className={characterField}
                  disabled
                />
              </div>
              {errorMsg("rosettaMovieID")}
              <div className={pair}>
                <TextField
                  name="trailerUrl"
                  onChange={e => handleChange(e, setTrailerURL)}
                  value={trailerUrl}
                  variant="outlined"
                  label="Youtube trailer URL"
                  className={characterField}
                />
              </div>
              {errorMsg("brightcoveId")}
              <div className={pair}>
                <div className="inline-uploader">
                  <span class="btn-file">
                    Upload Web image
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        e.preventDefault();
                        uploadImage("normal", e.target.files[0]);
                      }}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                  <span className="side-text">
                    {isWebUploading
                      ? "Uploading..."
                      : webImage || "No file chosen"}
                  </span>
                  {webImage ? (
                    <button onClick={() => setWebImage(null)}>
                      <Close />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className={pair}>
                <div className="inline-uploader">
                  <span class="btn-file">
                    Upload Mobile image
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        e.preventDefault();
                        uploadImage("mobile", e.target.files[0]);
                      }}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                  <span className="side-text">
                    {ismobileUploading
                      ? "Uploading..."
                      : mobileImage || "No file chosen"}
                  </span>
                  {mobileImage ? (
                    <button onClick={() => setMobileImage(null)}>
                      <Close />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className={pair}>
                <div className="inline-uploader">
                  <span class="btn-file">
                    Choose thumbnail image
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        e.preventDefault();
                        uploadImage("thumbnail", e.target.files[0]);
                      }}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                  <span className="side-text">
                    {isThumbnailUploading
                      ? "Uploading..."
                      : thumbnailImage || "No file chosen"}
                  </span>
                  {thumbnailImage ? (
                    <button onClick={() => setThumbnailImage(null)}>
                      <Close />
                    </button>
                  ) : null}
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            CLOSE
          </Button>
          <Button
            form="form-test"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "add"
              ? loading
                ? "ADDING..."
                : "ADD BANNER"
              : loading
              ? "EDITING"
              : "EDIT BANNER"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddHeroBanner;
