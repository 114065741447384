import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  formBg: {
    backgroundColor: "#16161A",
    padding: "75px 40px 45px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "stretch"
  },
  textField: {
    width: "100%"
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#FFFFFF"
  },
  fileUploadContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center"
  },
  fileUploadHeading: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#FFFFFF",
    marginBottom: "14px",
    "& p": {
      margin: 0
    },
    "& span": {
      fontSize: "14px",
      fontStyle: "italic"
    }
  },
  fileUploadBtn: {
    width: "fit-content",
    borderRadius: "5px",
    height: "52px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 40px",
    backgroundColor: "#272729",
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: 700,
    cursor: "pointer"
  },
  inputFile: {
    appearance: "none",
    width: 0,
    height: 0,
    fontSize: 0
  },
  submitBtn: {
    width: "184px",
    height: "61px",
    "& > *": {
      width: "100%",
      height: "100%",
      color: "#0E0200",
      fontSize: "23px",
      fontWeight: 500
    }
  },
  formHeading: {
    fontSize: "40px",
    fontWeight: 500,
    color: "#FFFFFF",
    lineHeight: "46.88px",
    marginBottom: "32px"
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "22px"
  },
  formPair: {
    display: "flex",
    gap: "22px",
    width: "100%",
    alignItems: "center"
  },
  formSubHeading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#606063",
    lineHeight: "42.19px"
  },
  formField: {
    width: "100%"
  },
  actionBtnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "14px",
    "& button": {
      width: "160px",
      height: "44px"
    }
  },
  activate: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginTop: "12px"
  },
  activateText: {
    fontSize: "16px"
  },
  imageUpload: {
    // "margin-top": "20px",
    width: "100%"
    // "min-width": "247px",
  }
}));

export default useStyles;
