import React from "react";

const Delete = props => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 15.0003H14.1667M14.1667 15.0003H27.5M14.1667 15.0003V26.667C14.1667 27.109 14.3423 27.5329 14.6548 27.8455C14.9674 28.1581 15.3913 28.3337 15.8333 28.3337H24.1667C24.6087 28.3337 25.0326 28.1581 25.3452 27.8455C25.6577 27.5329 25.8333 27.109 25.8333 26.667V15.0003H14.1667ZM16.6667 15.0003V13.3337C16.6667 12.8916 16.8423 12.4677 17.1548 12.1551C17.4674 11.8426 17.8913 11.667 18.3333 11.667H21.6667C22.1087 11.667 22.5326 11.8426 22.8452 12.1551C23.1577 12.4677 23.3333 12.8916 23.3333 13.3337V15.0003M18.3333 19.167V24.167M21.6667 19.167V24.167"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Edit = props => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3067_873)">
      <path
        d="M24.168 12.4999C24.3868 12.2811 24.6467 12.1074 24.9326 11.989C25.2186 11.8705 25.5251 11.8096 25.8346 11.8096C26.1442 11.8096 26.4507 11.8705 26.7366 11.989C27.0226 12.1074 27.2824 12.2811 27.5013 12.4999C27.7202 12.7188 27.8938 12.9786 28.0122 13.2646C28.1307 13.5506 28.1917 13.8571 28.1917 14.1666C28.1917 14.4761 28.1307 14.7826 28.0122 15.0686C27.8938 15.3546 27.7202 15.6144 27.5013 15.8333L16.2513 27.0833L11.668 28.3333L12.918 23.7499L24.168 12.4999Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3067_873">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Vectors = {
  Delete,
  Edit
};

export default Vectors;
