import React from "react";

const InfoBlue = props => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3145_10494)">
      <g opacity={0.95924}>
        <g opacity={0.95924}>
          <g opacity={0.95924}>
            <g opacity={0.95924}>
              <g opacity={0.95924}>
                <g opacity={0.95924}>
                  <g opacity={0.95924}>
                    <path
                      opacity={0.690546}
                      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                      fill="#A233E2"
                    />
                    <path
                      opacity={0.95924}
                      d="M10.2476 3.85164C10.817 4.24036 10.9877 4.98029 10.6291 5.50437C10.2705 6.02844 9.51833 6.13816 8.94902 5.74943C8.37971 5.36071 8.20891 4.62078 8.56752 4.0967C8.92614 3.57263 9.6784 3.46296 10.2476 3.85164Z"
                      fill="white"
                    />
                    <g opacity={0.95924}>
                      <g opacity={0.95924} filter="url(#filter0_d_3145_10494)">
                        <path
                          d="M8.30409 13.0063L9.77071 9.35994C9.87958 9.08872 9.78693 8.79847 9.52899 8.60243C9.27151 8.40632 8.89636 8.3435 8.55711 8.43727L7.98727 8.65097C7.79957 8.72136 7.70448 8.93057 7.77487 9.11827C7.7771 9.12422 7.77949 9.13011 7.78203 9.13593C7.86791 9.33282 8.09239 9.4286 8.29395 9.35438L8.86829 9.14288L7.40159 12.7889C7.27507 13.1042 7.36571 13.4388 7.64429 13.6833C7.92287 13.9278 8.33523 14.0354 8.74796 13.9708L9.57308 13.6466C9.77312 13.5681 9.88454 13.354 9.83412 13.145C9.7918 12.9697 9.61533 12.8618 9.43996 12.9041C9.4254 12.9076 9.41109 12.9121 9.39715 12.9176L8.57203 13.2418C8.45352 13.2603 8.37864 13.2078 8.35229 13.1846C8.32541 13.1611 8.26755 13.097 8.30409 13.0063Z"
                          fill="white"
                        />
                      </g>
                      <path
                        opacity={0.95924}
                        d="M8.30409 13.0063L9.77071 9.35994C9.87958 9.08872 9.78693 8.79847 9.52899 8.60243C9.27151 8.40632 8.89636 8.3435 8.55711 8.43727L7.98727 8.65097C7.79957 8.72136 7.70448 8.93057 7.77487 9.11827C7.7771 9.12422 7.77949 9.13011 7.78203 9.13593C7.86791 9.33282 8.09239 9.4286 8.29395 9.35438L8.86829 9.14288L7.40159 12.7889C7.27507 13.1042 7.36571 13.4388 7.64429 13.6833C7.92287 13.9278 8.33523 14.0354 8.74796 13.9708L9.57308 13.6466C9.77312 13.5681 9.88454 13.354 9.83412 13.145C9.7918 12.9697 9.61533 12.8618 9.43996 12.9041C9.4254 12.9076 9.41109 12.9121 9.39715 12.9176L8.57203 13.2418C8.45352 13.2603 8.37864 13.2078 8.35229 13.1846C8.32541 13.1611 8.26755 13.097 8.30409 13.0063Z"
                        fill="white"
                        stroke="white"
                        strokeWidth={0.6}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_3145_10494"
        x={-76.6562}
        y={-75.6055}
        width={182.504}
        height={185.594}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={6} dy={6} />
        <feGaussianBlur stdDeviation={45} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.052314 0 0 0 0 0.0393209 0 0 0 0 0.185405 0 0 0 0.480305 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3145_10494"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3145_10494"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3145_10494">
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InfoBlue;
