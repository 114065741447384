import api from "./instance";

export default {
  getList: token =>
    api
      .get("/voucher-groups?$include=vouchers,generatedBy", {
        headers: { Authorization: token }
      })
      .then(res => res),
  addBulkVouchers: (token, payload) =>
    api
      .post("/generate-subscription-vouchers", payload, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
