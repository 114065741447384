import api from "./instance";

export default {
  getList: (token, page) =>
    api
      .get(`/movie/banners?page=${page}&perPage=10`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  addHeroBanner: (token, payload) =>
    api
      .post("/movie/create-banner", payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editHeroBanner: (token, payload, id) =>
    api
      .patch(`/movie/banners/${id}`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  getHeroBanner: (token, id) =>
    api
      .get(`/hero-banners/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  patchHeroBanner: (token, id, payload) =>
    api
      .patch(`/hero-banners/${id}`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  deleteHeroBanner: (token, id) =>
    api
      .delete(`/movie/banner/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
