import api from "./instance";

export default {
  // login: credentials =>
  //   api.post("/admin/login", { ...credentials }).then(res => res),
  // logout: token =>
  //   api
  //     .post(
  //       "/admin/logout",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json; charset=utf-8"
  //         }
  //       }
  //     )
  //     .then(res => res),
  login: credentials =>
    api.post("/auth/login", { ...credentials }).then(res => res),
  getAdmin: async token => {
    const res = await api.get(`/users`, {
      headers: { Authorization: token }
    });
    return res;
  },
  logout: token =>
    api
      .post(
        "/auth/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8"
          }
        }
      )
      .then(res => res),
  // getAdmin: (token) =>
  //   api
  //     .post(
  //       "/auth/getUsers",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json; charset=utf-8",
  //         },
  //       }
  //     )
  //     .then((res) => res),
  resetPasswordInit: email =>
    api.post(`/auth/forgot-password`, { email }).then(res => res),
  resetPasswordComp: ({ password, token }) =>
    api.post(`/admin/forgot-password/${token}`, { password }).then(res => res)
};
