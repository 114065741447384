import React from "react";
import AllEvents from "./AllEvents";
import CreateEvent from "./CreateEvent";

export default [
  {
    path: "/dashboard/event-cinema",
    exact: true,
    component: () => <AllEvents />
  },
  {
    path: "/dashboard/event-cinema/create",
    exact: true,
    component: () => <CreateEvent />
  }
];
