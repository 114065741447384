import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import Switch from "../../../../components/Switch/Switch";
import Back from "../../../../components/Button/Back";
import fileApi from "../../../../api/file";
import { useDispatch } from "react-redux";
import types from "../../../../redux/types";
import { getRequestError } from "../../../../utils/functions";
import useStyles from "./style";
import { useHistory } from "react-router-dom";
import { MEDIA_TYPE } from "../../../../utils/constants";

const FileUpload = ({ description, url, setUrl, placeholder, name }) => {
  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const {
    textField,
    fileUploadContainer,
    fileUploadHeading,
    fileUploadBtn,
    inputFile,
    imageUpload
  } = useStyles();

  const uploadImage = async (type, file) => {
    const payload = new FormData();
    payload.append("file", file);

    setIsUploading(true);

    try {
      const response = await fileApi.uploadImageWithFile(payload);
      const image = response.data.data;
      setUrl(image);
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(error)
        }
      });
    }
  };

  return (
    <div className={imageUpload}>
      <div className="inline-uploader">
        <span className="btn-file">
          {name}
          <input
            type="file"
            className="custom-file-input"
            onChange={e => {
              e.preventDefault();
              uploadImage("thumbnail", e.target.files[0]);
            }}
            accept={
              MEDIA_TYPE.image.map(type => type).join(",") +
              "," +
              MEDIA_TYPE.video.map(type => type).join(",")
            }
          />
        </span>
        <span className="side-text">
          {isUploading ? "Uploading..." : (url && url) || "No file chosen"}
        </span>
      </div>
      {/* {imageFile && (
        <img
          className={uploadedImage}
          alt=""
          src={
            imageFile.type === "uploaded"
              ? imageFile.name
              : URL.createObjectURL(imageFile)
          }
        />
      )} */}
    </div>
    // <div className={fileUploadContainer}>
    //   <div>
    //     <div className={fileUploadHeading}>{description}</div>
    //     <TextField
    //       disabled
    //       name={name}
    //       value={url}
    //       variant="outlined"
    //       placeholder={placeholder}
    //       //   label="Title"
    //       className={textField}
    //     />
    //   </div>
    //   <label className={fileUploadBtn} disabled={isUploading}>
    //     {isUploading ? "Uploading" : "Choose file"}
    //     <input
    //       type="file"
    //       className={inputFile}
    //       onChange={(e) => {
    //         e.preventDefault();
    //         uploadImage("thumbnail", e.target.files[0]);
    //       }}
    //       accept="image/x-png,image/gif,image/jpeg,image/jpg"
    //     />
    //   </label>
    // </div>
  );
};

const ManagePromotion = ({ handleAction, formTitle, data, type }) => {
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [webUrl, setWebUrl] = useState(data.webMedia);
  const [mobileUrl, setMobileUrl] = useState(data.mobileMedia);
  const [thumbnailUrl, setThumbnailUrl] = useState(data.thumbnailMedia);
  const [primaryCTA, setPrimaryCTA] = useState(data.primaryCTA);
  const [secondaryCTA, setSecondaryCTA] = useState(data.secondaryCTA);
  const [primaryURL, setPrimaryURL] = useState(data.primaryURL);
  const [secondaryURL, setSecondaryURL] = useState(data.secondaryURL);
  const [isActive, setIsActive] = useState(data.isVisibleOnHomepage);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const {
    textField,
    formBg,
    heading,
    submitBtn,
    fileUploadBtn,
    fileUploadContainer,
    fileUploadHeading,
    inputFile,
    actionBtnCont,
    form,
    formField,
    formHeading,
    formPair,
    formSubHeading,
    activate,
    activateText
  } = useStyles();

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!webUrl || !thumbnailUrl || !mobileUrl) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: "Please upload a media file and thumbnail"
        }
      });
      return;
    }
    setLoading(true);
    let payload = {
      web_media: webUrl,
      mobile_media: mobileUrl,
      thumbnail: thumbnailUrl,
      position: "body",
      isMovie: false,
      isActive: true,
      isVisibleOnHomepage: isActive,
      ctaOptions: {
        primaryCTA: primaryCTA,
        secondaryCTA: secondaryCTA ?? null,
        primaryCTAUrl: primaryURL,
        secondaryCTAUrl: secondaryURL ?? null
      },
      title: title,
      description: description
    };
    handleAction(payload)
      .then(res => {})
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <h1 className={formHeading}>{formTitle}</h1>
      <form
        id="manage-promotion-form"
        className={form}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <h2 className={formSubHeading}>OVERVIEW</h2>
        <TextField
          name="title"
          onChange={e =>
            handleChange(e, setTitle, value => value.length <= 100)
          }
          value={title}
          variant="outlined"
          label="Name"
          className={formField}
          required
        />
        <TextField
          name="description"
          onChange={e => handleChange(e, setDescription)}
          value={description}
          multiline
          rows={3}
          variant="outlined"
          label="Description"
          style={{ flex: 1 }}
          required
        />{" "}
        <FileUpload
          name="CHOOSE WEB AD MEDIA"
          placeholder="Mobile image url"
          description={
            <p>
              Mobile Screen Display <span>*Recommended: 375 by 580px</span>
            </p>
          }
          url={webUrl}
          setUrl={setWebUrl}
        />
        <FileUpload
          name="CHOOSE MOBILE AD MEDIA"
          placeholder="Mobile image url"
          description={
            <p>
              Mobile Screen Display <span>*Recommended: 375 by 580px</span>
            </p>
          }
          url={mobileUrl}
          setUrl={setMobileUrl}
        />
        <FileUpload
          name="CHOOSE THUMBNAIL"
          placeholder="Mobile image url"
          description={
            <p>
              Mobile Screen Display <span>*Recommended: 375 by 580px</span>
            </p>
          }
          url={thumbnailUrl}
          setUrl={setThumbnailUrl}
        />
        <div className={formPair}>
          <TextField
            name="primary-cta"
            onChange={e => handleChange(e, setPrimaryCTA)}
            value={primaryCTA}
            variant="outlined"
            label="Primary CTA "
            className={formField}
            required
          />
          <TextField
            name="primary-url"
            onChange={e => handleChange(e, setPrimaryURL)}
            value={primaryURL}
            variant="outlined"
            label="URL"
            className={formField}
            required
          />
        </div>
        <div className={formPair}>
          <TextField
            name="secondary-cta"
            onChange={e => handleChange(e, setSecondaryCTA)}
            value={secondaryCTA}
            variant="outlined"
            label="Secondary CTA "
            className={formField}
          />
          <TextField
            name="secondary-url"
            onChange={e => handleChange(e, setSecondaryURL)}
            value={secondaryURL}
            variant="outlined"
            label="URL"
            className={formField}
          />
        </div>
        <div className={activate}>
          <p className={activateText}>Activate on the Landing page</p>
          <Switch
            defaultChecked={false}
            checked={isActive}
            onChange={() => {
              setIsActive(!isActive);
            }}
            disabled={loading}
          />
        </div>
        <div className={actionBtnCont}>
          <Button
            onClick={() => {
              history.push("/dashboard/promotions");
            }}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            CANCEL
          </Button>
          <Button
            form="manage-promotion-form"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "add"
              ? loading
                ? "ADDING..."
                : "ADD"
              : loading
              ? "SAVING"
              : "SAVE"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ManagePromotion;
