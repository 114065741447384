import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../../api/movie-entry-api";
import fileApi from "../../../api/file";
import checks from "./functions/fieldsCheck/checks";
import { forIn, cloneDeep } from "lodash";

import urls from "../../../api/urls";
import validator from "validator";
import { withRouter } from "react-router-dom";
import fieldsCheck from "./functions/fieldsCheck";
import descriptionReset from "./functions/descriptionReset";
import textFieldChange from "./functions/textFieldChange";
import buildPayload from "./functions/payload/buildPayload2";
import { populateForm } from "./functions/stateUpdater";

import genreParser from "../../../components/functions/genreParser";
import addCastMember from "./functions/casts/addCastMember";

import removeCastMember from "./functions/casts/removeCastMember";

import MovieEntryContainer from "../../../containers/MovieEntry";

import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import moment from "moment";
import { CircularLoad } from "../../../components/Loader";
const { movieDB } = urls;

class MovieEntryPatch2 extends Component {
  state = {
    movieLoading: true,
    form: {
      featured: { value: "", error: { isError: false, message: "" } },
      genres: { value: [], error: { isError: false, message: "" } },
      overview: { value: "", error: { isError: false, message: "" } },
      tags: { value: [], error: { isError: false, message: "" } },
      title: { value: "", error: { isError: false, message: "" } },
      trailerURL: { value: "", error: { isError: false, message: "" } },
      isApproved: { value: false, error: { isError: false, message: "" } },
      type: {
        value: "",
        error: { isError: false, message: "" }
      },
      upcoming: { value: "", error: { isError: false, message: "" } },
      posterImage: {
        value: "",
        error: { isError: false, message: "" }
      },
      posterImageSmall: {
        value: "",
        error: { isError: false, message: "" }
      },
      backdropImage: {
        value: "",
        error: { isError: false, message: "" }
      },
      backdropImageSmall: {
        value: "",
        error: { isError: false, message: "" }
      },
      casts: { value: [], error: { isError: false, message: "" } },
      releaseYear: { value: "", error: { isError: false, message: "" } },
      openingDate: {
        value: moment(new Date()).format("MM-DD-YYYY"),
        error: { isError: false, message: "" }
      },
      video: { value: "", error: { isError: false, message: "" } },
      featureLength: { value: "", error: { isError: false, message: "" } }
    },
    status: {
      loading: false,
      qf: false,
      isSubmittable: false,
      required: [
        "overview",
        "title",
        "genres",
        "overview",
        "trailerURL",
        "posterImage",
        "posterImageSmall",
        "backdropImage",
        "backdropImageSmall",
        "releaseYear",
        // "openingDate",
        "featureLength"
      ],
      isApproved: false,
      missing: [],
      isShowTimeAvailable: false
    },
    vista: {
      casts: false,
      overview: false,
      genres: false
    },
    unfilledRequirements: []
  };

  componentDidMount() {
    const { location, match } = this.props;
    console.log(match.params.id);
    if (location.state?.movieName) {
      this.setState({
        form: {
          ...this.state.form,
          title: { ...this.state.form.title, value: location.state.movieName }
        }
      });
    }
    this.setState({
      movieLoading: true
    });
    api
      .getSingleMovieFHDB(match.params.id, this.props.jwt)
      .then(res => {
        const result = res?.data?.data?.movie_details_from_db;
        this.setState({
          isShowTimeAvailable: res?.data?.data?.is_show_time_available,
          form: {
            ...this.state.form,
            genres: {
              value: result?.genre.split(",") ?? [],
              error: { isError: false, message: "" }
            },
            overview: {
              value: result?.description ?? "",
              error: { isError: false, message: "" }
            },
            title: {
              value:
                result?.movie_title ?? this.state?.form?.title?.value ?? "",
              error: { isError: false, message: "" }
            },
            trailerURL: {
              value: result?.youtube_trailer ?? "",
              error: { isError: false, message: "" }
            },
            isApproved: {
              value: result?.is_available_for_display ?? false,
              error: { isError: false, message: "" }
            },
            posterImage: {
              value: result?.big_poster ?? "",
              error: { isError: false, message: "" }
            },
            posterImageSmall: {
              value: result?.small_poster ?? "",
              error: { isError: false, message: "" }
            },
            backdropImage: {
              value: result?.big_backround ?? "",
              error: { isError: false, message: "" }
            },
            backdropImageSmall: {
              value: result?.small_background ?? "",
              error: { isError: false, message: "" }
            },
            casts: {
              value: result?.movie_cast_list.map((item, i) => ({
                id: item?.id ?? i,
                profileImage: item?.image ?? null,
                name: item?.original_name ?? "",
                order: i,
                character: item?.character_name ?? ""
              })),
              error: { isError: false, message: "" }
            },
            releaseYear: {
              value: result?.release_year ?? "",
              error: { isError: false, message: "" }
            },
            upcoming: {
              value: result?.is_coming_soon === true ? "true" : "false",
              error: { isError: false, message: "" }
            },
            openingDate: {
              value:
                result?.is_coming_soon_date ??
                moment(new Date()).format("MM-DD-YYYY"),
              error: { isError: false, message: "" }
            },
            featureLength: {
              value: result?.movie_run_time ?? "",
              error: { isError: false, message: "" }
            }
          },
          status: {
            ...this.state.status,
            isApproved: result?.is_available_for_display ?? false
          }
        });
        this.setState({
          movieLoading: false
        });
      })
      .catch(e => {
        getRequestError(e);
        this.setState({
          movieLoading: false
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.state;

    if (prevState.form !== form) {
      this.setState(this.checkEmptyFields());
      const formClone = cloneDeep(form);
      const formRefactored = forIn(formClone, (value, key, obj) => {
        obj[key] = obj[key].value;
      });
      this.checkUnfilledRequirements(formRefactored);
    }
  }

  postImageUrl = async field => {
    this.setState({
      isPostingImage: true
    });
    // const url = this.state.form[`${field}`].value;

    // try {
    //   const response = await api.post(`${movieDB}/aws/s3/uploadWithUrl`, {
    //     url,
    //     type: "movieImage"
    //   });

    //   this.setState({
    //     form: {
    //       ...this.state.form,
    //       [field]: {
    //         value: response.data.url,
    //         error: { isError: false, message: "" }
    //       }
    //     },
    //     isPostingImage: false
    //   });
    // } catch (error) {
    //   this.setState({
    //     isPostingImage: false
    //   });
    // }
  };

  handleChange = (e, _validator) => {
    e.persist();
    const checkedValidator =
      typeof _validator === "function"
        ? _validator
        : val =>
            validator.isEmpty(val)
              ? { isError: true, message: "Field is empty" }
              : { isError: false, message: "" };
    this.setState(
      textFieldChange(e, checkedValidator),
      this.checkEmptyFields()
    );
  };

  handleSelectChange = name => ({ target }, validator) => {
    const { checked } = target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        type: {
          ...form.type,
          value: { ...form.type.value, [name]: checked },
          error: { isError: false, message: "" }
        }
      }
    });
  };

  formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  handleDateChange = date => {
    const value = this.formatDate(date);
    console.log(value);
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        openingDate: {
          ...form.type,
          value: value
        }
      }
    });
  };

  handleTagChange = (taggingName, values) => {
    let compileValues;
    try {
      compileValues = values.map(value => value.value);
    } catch (error) {
      compileValues = [];
    }
    this.setState({
      form: {
        ...this.state.form,
        [taggingName]: { value: compileValues }
      }
    });
  };

  resetDescription = e => {
    const { overview } = this.props.db;
    const checkedDesc = overview ? overview : "no description";
    this.setState(descriptionReset(checkedDesc));
  };

  checkEmptyFields = () => this.setState(fieldsCheck);

  addCast = cast => {
    this.setState(addCastMember(cast));
  };
  removeCast = cast => {
    this.setState(removeCastMember(cast, "order"));
  };

  checkUnfilledRequirements = payload => {
    const {
      overview,
      posterImage,
      backdropImage,
      censorRating,
      genres,
      releaseYear
    } = payload;
    const required = {
      overview,
      posterImage,
      backdropImage,
      censorRating,
      genres,
      releaseYear
    };

    const unfilledRequirements = Object.keys(required).filter((item, i) => {
      if (!required[item]) {
        return item;
      } else {
        return null;
      }
    });

    // if ( unfilledRequirements && unfilledRequirements.length === 0 )
    // {
    //   this.setState( {
    //     form:
    //     {
    //       ...this.state.form,
    //       isApproved: {
    //         value: true,
    //         error: { isError: false, message: "" }
    //       }
    //     }
    //   } );
    // } else
    // {
    //   this.setState( {
    //     form:
    //     {
    //       ...this.state.form,
    //       isApproved: {
    //         value: false,
    //         error: { isError: true, message: "Missing fields" }
    //       }
    //     }
    //   } );
    // }
    this.setState({ unfilledRequirements });
    return unfilledRequirements;
  };

  uploadImageFileAPI = async (file, field) => {
    const { dispatch } = this.props;

    const { ALERT_OPEN } = types;
    const payload = new FormData();
    payload.append("file", file);
    try {
      const response = await fileApi.uploadImageWithFile(payload);
      this.setState({
        form: {
          ...this.state.form,
          [field]: {
            value: response.data.data,
            error: { isError: false, message: "" }
          }
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(error)
        }
      });
    }
  };

  handleApproveForDisplay = async status => {
    // const { jwt, match, dispatch } = this.props;

    // const { ALERT_OPEN } = types;

    // const id = match.params.id;

    this.setState({
      form: {
        ...this.state.form,
        isApproved: {
          value: status,
          error: { isError: false, message: "" }
        }
      },
      status: {
        ...this.state.status,
        isApproved: status
      }
    });

    // api
    //   .setMovieVisibility(
    //     id,
    //     {
    //       isApproved: status
    //     },
    //     `Bearer ${jwt}`
    //   )
    //   .then(res => {
    //     this.setState({
    //       form: {
    //         ...this.state.form,
    //         isApproved: {
    //           value: status,
    //           error: { isError: false, message: "" }
    //         }
    //       },
    //       status: {
    //         ...this.state.status,
    //         isApproved: status
    //       }
    //     });
    //     dispatch({
    //       type: ALERT_OPEN,
    //       payload: {
    //         severity: "success",
    //         message: "Movie updated successfully"
    //       }
    //     });
    //   })
    //   .catch(err => {
    //     dispatch({
    //       type: ALERT_OPEN,
    //       payload: {
    //         severity: "error",
    //         message: getRequestError(err)
    //       }
    //     });
    //   });
  };

  storeMovieDetail = async (fields, movie) => {
    [
      "title",
      "overview",
      "genres",
      "casts",
      "releaseYear",
      "featureLength"
    ].forEach(item => {
      if (fields.includes(item)) {
        this.setState({
          form: {
            ...this.state.form,
            [item]: {
              ...this.state.form[item],
              value: movie[item],
              error: { isError: false, message: "" }
            }
          }
        });
      }
    });
    const images = [
      "backdropImage",
      "posterImage",
      "posterImageSmall",
      "backdropImageSmall"
    ];
    for (const [_i, image] of images.entries()) {
      if (fields.includes(image)) {
        const res = await api.formatMovieDBImage(movie[image]);
        this.setState({
          form: {
            ...this.state.form,
            [image]: {
              ...this.state.form[image],
              value: res ?? this.state.form[image].value
            }
          }
        });
      }
    }
  };

  postPayload = async e => {
    e.preventDefault();
    const {
      jwt,
      match,
      // pageLoadStopped,
      dispatch
    } = this.props;

    // const { form } = this.state;
    this.checkEmptyFields();
    const {
      form: {
        backdropImage,
        backdropImageSmall,
        casts,
        featureLength,
        genres,
        overview,
        posterImage,
        posterImageSmall,
        releaseYear,
        title,
        trailerURL,
        isApproved,
        upcoming,
        openingDate
      }
    } = this.state;
    const { isSubmittable } = this.state.status;
    if (!isSubmittable) return;
    // const payload = buildPayload(db, this.state.form);

    // this.checkUnfilledRequirements(payload);

    const { ALERT_OPEN } = types;
    this.setState({
      status: {
        ...this.state.status,
        loading: true
      }
    });

    const rosettaId = match.params.id;
    api
      .createFHMovie(jwt, {
        rosetta_movie_id: Number(rosettaId),
        movie_title: title.value,
        description: overview.value,
        genre: genres.value.join(","),
        // movieType: "CINEMA",
        big_poster: posterImage.value,
        small_poster: posterImageSmall.value,
        big_background: backdropImage.value,
        small_background: backdropImageSmall.value,
        youtube_trailer_link: trailerURL.value,
        release_year: Number(releaseYear.value),
        movie_run_time: Number(featureLength.value),
        feature: "N0",
        tags: "tama, mata, mtaa, yopmai",
        censorship_rating: "tama",
        is_available_for_display: isApproved.value,
        is_coming_soon: upcoming.value === "true" ? true : false,
        is_coming_soon_date: upcoming.value === "true" ? openingDate.value : "",
        cast: casts.value?.map(item => ({
          original_name: item?.name ?? "",
          character_name: item?.character ?? "",
          image: item?.profileImage ?? ""
        }))
      })
      .then(res => {
        this.setState({
          status: {
            ...this.state.status,
            loading: false
          }
        });
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: "Movie updated successfully"
          }
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          status: {
            ...this.state.status,
            loading: false
          }
        });
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      });
    // const timestamp = Date.parse(payload.openingDate);

    // if (isNaN(timestamp) && payload.openingDate) {
    //   return this.setState({
    //     status: {
    //       ...this.state.status,
    //       postError: {
    //         value: true,
    //         message: "Invalid Date format"
    //       }
    //     }
    //   });
    // }
  };

  render() {
    const { jwt } = this.props;
    const {
      unfilledRequirements,
      movieLoading,
      isShowTimeAvailable
    } = this.state;
    const { isApproved, missing, loading, isSubmittable } = this.state.status;

    const {
      title,
      overview,
      type,
      tags,
      featured,
      upcoming,
      genres,
      posterImage,
      backdropImage,
      casts,
      releaseYear,
      openingDate,
      featureLength,
      posterImageSmall,
      backdropImageSmall,
      trailerURL
    } = this.state.form;

    const castsSourse = casts.value;

    return (
      <>
        {movieLoading ? (
          <CircularLoad show />
        ) : (
          <MovieEntryContainer
            jwt={jwt}
            create
            title={title}
            description={overview}
            type={type}
            selectedGenres={genres.value}
            tags={tags.value}
            featured={featured.value}
            upcoming={upcoming.value}
            descReset={this.resetDescription}
            onClick={this.postPayload}
            onChange={this.handleChange}
            onTagChange={this.handleTagChange}
            onSelect={this.handleSelectChange}
            handleVodPrice={this.handleVodChange}
            posterImage={posterImage}
            posterImageSmall={posterImageSmall}
            backdropImage={backdropImage}
            backdropImageSmall={backdropImageSmall}
            cast={castsSourse}
            addCast={this.addCast}
            removeCast={this.removeCast}
            handleTrailerURL={this.handleChange}
            releaseYear={releaseYear}
            isSubmittable={isSubmittable}
            isApproved={isApproved}
            unfilledRequirements={unfilledRequirements}
            loading={loading}
            uploadImageFileAPI={this.uploadImageFileAPI}
            handleDateChange={this.handleDateChange}
            openingDate={openingDate.value}
            featureLength={featureLength}
            patcher={true}
            handleApproveForDisplay={this.handleApproveForDisplay}
            useSmall
            missing={missing}
            storeMovieDetail={this.storeMovieDetail}
            trailerURL={trailerURL}
            isShowTimeAvailable={isShowTimeAvailable}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MovieEntryPatch2));
