import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import {
  Typography,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import urls from "../../../../api/urls";
import movieApi from "../../../../api/movie-entry-api";
import fileApi from "../../../../api/file";
import { Autocomplete } from "@material-ui/lab";
import empty from "../../../../assets/images/empty-concessions.png";
import { useDispatch } from "react-redux";
import { debounce, getRequestError } from "../../../../utils/functions";
import types from "../../../../redux/types";
import Close from "../../../../vectors/Close";
import FormValidator from "../../../../utils/FormValidator";
import { MEDIA_TYPE } from "../../../../utils/constants";
const { movieDB } = urls;

const AddHeroBanner = ({ open, handleClose, add, edit, jwt, type, data }) => {
  const {
    castsDialog,
    pair,
    errorMsgStyle,
    characterField,
    dropDown
  } = useStyles();
  const [title, setTitle] = useState("");
  const [promotionType, setPromotionType] = useState("");
  const [description, setDescription] = useState();
  const [rosettaMovieID, setRosettaMovieID] = useState("");
  const [loading, setLoading] = useState();
  const [webMedia, setWebMedia] = useState("");
  const [mobileMedia, setMobileMedia] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [isWebUploading, setIsWebUploading] = useState(false);
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [ismobileUploading, setIsMobileUploading] = useState(false);
  const [trailerUrl, setTrailerURL] = useState("");
  const [url, setURL] = useState("");
  const [searchedMovie, setSearchedMovie] = useState([]);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleMovieSearch = async search => {
    try {
      const res = await movieApi.searchMovieFHDB(search, jwt);
      if (res.data.data) {
        setSearchedMovie(
          res?.data?.data?.map(item => ({
            id: item?.id,
            rosettaId: item?.rosetta_movie_id,
            movieTitle: item?.movie_title,
            label: item?.movie_title,
            description: item?.description,
            youtubeTrailerURL: item?.youtube_trailer,
            img: item?.small_poster ?? empty,
            webMedia: item?.big_backround,
            mobileMedia: item?.small_background,
            thumbnailImage: item?.small_poster
          })) ?? []
        );
      } else {
        setSearchedMovie([]);
      }
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
    }
  };

  const validator = new FormValidator([
    {
      field: "title",
      method: "isEmpty",
      validWhen: false,
      message: "Title cannot be empty."
    },
    {
      field: "promotionType",
      method: "isEmpty",
      validWhen: false,
      message: "Type cannot be empty."
    },
    {
      field: "description",
      method: "isLength",
      args: [{ min: 1, max: 500 }],
      validWhen: true,
      message: "Description must be between 1 - 300 chars."
    },
    {
      field: "rosettaMovieID",
      method: "isEmpty",
      validWhen: false,
      message: "Movie ID cannot be empty"
    },
    {
      field: "brightcoveId",
      method: "isURL",
      validWhen: true,
      message: "Trailer URL must be a valid URL."
    }
  ]);
  const [validation, setValidation] = useState(validator.reset());

  useEffect(() => {
    if (open && type === "add") {
      setTitle("");
      setDescription("");
      setTrailerURL("");
      setURL("");
      setThumbnailImage("");
      setWebMedia("");
      setMobileMedia("");
      setPromotionType("Movie");
    }
    setLoading(false);
  }, [open, type]);

  useEffect(() => {
    if (data) {
      setDescription(data.description);
      setRosettaMovieID(data.rosettaId);
      setTrailerURL(data.youtubeTrailerURL);
      setURL(data.url);
      setThumbnailImage(data.thumbnailImage);
      setWebMedia(data.webMedia);
      setMobileMedia(data.mobileMedia);
      setTitle(data.title);
      setPromotionType(data.type);
    }
  }, [data]);

  const extHandleee = () => {
    if (type === "add") {
      setTitle("");
      setDescription("");
      setRosettaMovieID("");
      setTrailerURL("");
      setURL("");
      setThumbnailImage("");
      setWebMedia("");
      setMobileMedia("");
      setPromotionType("Movie");
    }
    handleClose();
  };

  const uploadImage = async (type, file) => {
    const payload = new FormData();
    payload.append("file", file);

    if (type === "thumbnail") {
      setIsThumbnailUploading(true);
    } else if (type === "mobile") {
      setIsMobileUploading(true);
    } else {
      setIsWebUploading(true);
    }

    try {
      const response = await fileApi.uploadImageWithFile(payload);
      const image = response.data.data;

      if (type === "thumbnail") {
        setThumbnailImage(image);
        setIsThumbnailUploading(false);
      } else if (type === "mobile") {
        setMobileMedia(image);
        setIsMobileUploading(false);
      } else {
        setWebMedia(image);
        setIsWebUploading(false);
      }
    } catch (error) {
      setIsThumbnailUploading(false);
      setIsWebUploading(false);
      setIsMobileUploading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(error)
        }
      });
    }
  };

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let heroBannerPayload;
    heroBannerPayload = {
      title: title,
      web_media: webMedia,
      mobile_media: mobileMedia,
      thumbnail: thumbnailImage,
      position: "banner",
      isMovie: promotionType === "Movie"
    };
    if (description) {
      heroBannerPayload["description"] = description;
    }
    if (promotionType === "Movie") {
      heroBannerPayload["rosetta_movie_id"] = rosettaMovieID;
      heroBannerPayload["youtube_trailer"] = trailerUrl;
    }
    if (promotionType === "Promotion" && url) {
      heroBannerPayload["url"] = url;
    }
    const heroBannerValidation = {
      title,
      description: promotionType === "Movie" ? description : "pass",
      rosettaMovieID: promotionType === "Movie" ? rosettaMovieID : "pass",
      brightcoveId: promotionType === "Movie" ? trailerUrl : "www.pass.com",
      promotionType
    };

    const _validation = validator.validate(heroBannerValidation);
    if (_validation.isValid) {
      setValidation(_validation);
      if (!(webMedia || mobileMedia)) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Add media for either web or mobile"
          }
        });
        return;
      }
      if (webMedia && !thumbnailImage) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Add a thumbnail image for web"
          }
        });
        return;
      }
      setLoading(true);
      if (type === "add") {
        await add(heroBannerPayload).then(() => {
          setLoading(false);
        });
      } else if (type === "edit") {
        await edit(heroBannerPayload).then(() => {
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
      setValidation(_validation);
    }
  };

  const errorMsg = propertyName => {
    return (
      !validation[propertyName].isValid && (
        <p className={errorMsgStyle}>{validation[propertyName].message}</p>
      )
    );
  };

  return (
    <Dialog
      fullWidth="xl"
      open={open}
      onClose={extHandleee}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={castsDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">
            {type === "add" ? "Add" : "Edit"} Banner
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form
              id="form-test"
              style={{ marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <div className={pair}>
                <FormControl variant="outlined" className={dropDown}>
                  <InputLabel> Select Banner type </InputLabel>
                  <Select
                    disabled={type === "edit"}
                    value={promotionType}
                    onChange={e => handleChange(e, setPromotionType)}
                    onBlur={e => handleChange(e, setPromotionType)}
                    name="role"
                  >
                    {["Movie", "Promotion"].map((name, i) => {
                      return (
                        <MenuItem key={i} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {errorMsg("promotionType")}

              {promotionType == "Movie" ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={searchedMovie}
                  getOptionLabel={option => option.label}
                  onChange={(_, value) => {
                    if (value) {
                      setDescription(value.description);
                      setRosettaMovieID(value.rosettaId);
                      setTrailerURL(value.youtubeTrailerURL);
                      setThumbnailImage(value.thumbnailImage);
                      setWebMedia(value.webMedia);
                      setMobileMedia(value.mobileMedia);
                      setTitle(value.movieTitle);
                    }
                  }}
                  style={{ flex: 1 }}
                  renderInput={params => (
                    <div className={pair}>
                      <TextField
                        {...params}
                        label="Select Movie Title"
                        name="title"
                        value={title}
                        variant="outlined"
                        onChange={e => {
                          e.persist();
                          if (e.target.value) {
                            debounce(
                              () => handleMovieSearch(e.target.value),
                              1000
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                  renderOption={(props, option) => (
                    <Grid spacing={1} alignItems="center" component="li">
                      <Grid item>
                        <img
                          loading="lazy"
                          width="40"
                          height="40"
                          src={props?.img}
                          srcSet={props?.img}
                          alt=""
                        />
                      </Grid>
                      <Grid item>
                        <span>{props?.label ?? ""}</span>
                      </Grid>
                    </Grid>
                  )}
                />
              ) : (
                <div className={pair}>
                  <TextField
                    name="Title"
                    onChange={e => handleChange(e, setTitle)}
                    value={title}
                    variant="outlined"
                    label="Title"
                    className={characterField}
                  />
                </div>
              )}
              {/* </div> */}
              {errorMsg("title")}
              <div className={pair}>
                <TextField
                  name="description"
                  onChange={e => handleChange(e, setDescription)}
                  value={description}
                  multiline
                  rows={3}
                  variant="outlined"
                  label="Description"
                  style={{ flex: 1 }}
                />
              </div>
              {errorMsg("description")}
              {promotionType === "Movie" ? (
                <div className={pair}>
                  <TextField
                    name="rosettaMovieID"
                    onChange={e => handleChange(e, setRosettaMovieID)}
                    value={rosettaMovieID}
                    variant="outlined"
                    label="Movie ID"
                    className={characterField}
                    disabled
                  />
                </div>
              ) : null}
              {promotionType === "Movie" ? errorMsg("rosettaMovieID") : null}
              {promotionType === "Movie" ? (
                <div className={pair}>
                  <TextField
                    name="trailerUrl"
                    onChange={e => handleChange(e, setTrailerURL)}
                    value={trailerUrl}
                    variant="outlined"
                    label="Youtube trailer URL"
                    className={characterField}
                  />
                </div>
              ) : null}
              {promotionType === "Movie" ? errorMsg("brightcoveId") : null}
              {promotionType === "Promotion" ? (
                <div className={pair}>
                  <TextField
                    name="url"
                    onChange={e => handleChange(e, setURL)}
                    value={url}
                    variant="outlined"
                    label="URL (Optional)"
                    className={characterField}
                  />
                </div>
              ) : null}
              <div className={pair}></div>
              <div className={pair}>
                <div className="inline-uploader">
                  <span class="btn-file">
                    Upload Web media
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        e.preventDefault();
                        uploadImage("normal", e.target.files[0]);
                      }}
                      accept={
                        MEDIA_TYPE.image.map(type => type).join(",") +
                        "," +
                        MEDIA_TYPE.video.map(type => type).join(",")
                      }
                    />
                  </span>
                  <span className="side-text">
                    {isWebUploading
                      ? "Uploading..."
                      : webMedia || "No file chosen"}
                  </span>
                  {webMedia ? (
                    <button onClick={() => setWebMedia(null)}>
                      <Close />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className={pair}>
                <div className="inline-uploader">
                  <span class="btn-file">
                    Upload Mobile media
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        e.preventDefault();
                        uploadImage("mobile", e.target.files[0]);
                      }}
                      accept={
                        MEDIA_TYPE.image.map(type => type).join(",") +
                        "," +
                        MEDIA_TYPE.video.map(type => type).join(",")
                      }
                    />
                  </span>
                  <span className="side-text">
                    {ismobileUploading
                      ? "Uploading..."
                      : mobileMedia || "No file chosen"}
                  </span>
                  {mobileMedia ? (
                    <button onClick={() => setMobileMedia(null)}>
                      <Close />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className={pair}>
                <div className="inline-uploader">
                  <span class="btn-file">
                    Choose thumbnail image
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        e.preventDefault();
                        uploadImage("thumbnail", e.target.files[0]);
                      }}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                  <span className="side-text">
                    {isThumbnailUploading
                      ? "Uploading..."
                      : thumbnailImage || "No file chosen"}
                  </span>
                  {thumbnailImage ? (
                    <button onClick={() => setThumbnailImage(null)}>
                      <Close />
                    </button>
                  ) : null}
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            CLOSE
          </Button>
          <Button
            form="form-test"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "add"
              ? loading
                ? "ADDING..."
                : "ADD BANNER"
              : loading
              ? "EDITING"
              : "EDIT BANNER"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddHeroBanner;
