import api from "./instance";

export default {
  fetchTickets: token =>
    api
      .get("/ticket/ticket-breakdown", {
        headers: { Authorization: token }
      })
      .then(res => res)
};
