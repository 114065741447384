import api from "./instance";

export default {
  getList: (token, page, position) =>
    api
      .get(
        `/promotions/admin?pageNo=${page}&pageSize=50&position=${position}`,
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  getPromotion: (token, id) =>
    api
      .get(`/promotions/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  addPromotion: (token, payload) =>
    api
      .post("/promotions", payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editPromotion: (token, payload, id) =>
    api
      .put(`/promotions/${id}`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editPromotionStatus: (token, isActive, id) =>
    api
      .patch(
        `/promotions/${id}/status`,
        {
          isActive
        },
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  deletePromotion: (token, id) =>
    api
      .delete(`/promotions/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};

// import api from "api";

// export default {
//   getList: (token, page, position) =>
//     api
//       .get(
//         `/promotions/admin?pageNo=${page}&pageSize=10&position=${position}`,
//         {
//           headers: { Authorization: token }
//         }
//       )
//       .then(res => res),
//   getPromotion: (token, id) =>
//     api
//       .get(`/promotions/${id}`, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   addPromotion: (token, payload) =>
//     api
//       .post("/promotions", payload, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   editPromotion: (token, payload, id) =>
//     api
//       .put(`/promotions/${id}`, payload, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   editPromotionStatus: (token, isActive, id) =>
//     api
//       .patch(
//         `/promotions/${id}/status`,
//         {
//           isActive
//         },
//         {
//           headers: { Authorization: token }
//         }
//       )
//       .then(res => res),
//   deletePromotion: (token, id) =>
//     api
//       .delete(`/promotions/${id}`, {
//         headers: { Authorization: token }
//       })
//       .then(res => res)
// };
