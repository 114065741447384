// import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import Section from "../../../components/Section";
// import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
// import Point from "../../../components/Section/Point";
// import api from "../../../api/users-api";
// import Avatar from "../../../components/Avatar";
// import useStyles from "./styles";
// import moment from "moment";
// import GoBack from "../../../components/Button/GoBack";
// import { purchaseColumns } from "./purchaseHeadTable";
// import { CircularLoad } from "../../../components/Loader";
// import UserSlideIn from "../../../containers/UserList/UserSlideIn";
// import { DataGrid } from "@material-ui/data-grid";
// import { membershipHistoryColumns } from "./membershipHistoryTable";
// import DeleteDialog from "../../../components/DeleteDialog";
// import Camera from "../../../vectors/Camera";
// import { useDispatch } from "react-redux";
// import types from "../../../redux/types";
// import { getRequestError } from "../../../utils/functions";

// const UserProfilePage = ({ user, match }) => {
//   const [profile, setProfile] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     mobile: "",
//     dateOfBirth: null,
//     gender: "",
//     createdAt: null,
//     preferredBillingCycle: "",
//     subPlan: "",
//     profileImage: null,
//     cardNumber: "",
//   });
//   const [activeTab, setActiveTab] = useState(0);
//   const [pageLoading, setPageLoading] = useState(true);
//   const [membershipHistory, setMembershipHistory] = useState([]);
//   const [purchaseHistory, setPurchaseHistory] = useState([]);
//   const [isPhotoUpdating, setIsPhotoUpdating] = useState(false);
//   const [isClearingToken, setIsClearingToken] = useState(false);
//   const [isRenewingSubscription, setIsRenewingSubscription] = useState(false);
//   const [IsResetPassword, setIsResetPassword] = useState(false);
//   const [showPanel, setShowPanel] = useState(false);
//   const [isDeleting, setIsDeleting] = useState(false);
//   const [showDelete, setShowDelete] = useState(false);
//   const dispatch = useDispatch();

//   const { ALERT_OPEN } = types;
//   //
//   const userID = match.params.id;
//   const {
//     backBtn,
//     sectionContainer,
//     titleStyle,
//     section,
//     profileHead,
//     userName,
//     userNameTypo,
//     avatarSection,
//     uploadBtn,
//     profileTitle,
//     profileValue,
//     profileContent,
//     profileContentDivider,
//     profileContentPill,
//     profileContentButtonContainer,
//     profileHeading,
//     profileTagline,
//     tab,
//     tabHeader,
//     tabHeaderItem,
//     tabHeaderItemActive,
//   } = useStyles();

//   const fetchUser = () => {
//     api
//       .getUserInfo(userID, `Bearer ${user.jwt}`)
//       .then((res) => {
//         setPageLoading(false);
//         const {
//           userSubscriptionHistory = {},
//           userCompleteTransaction = {},
//         } = res?.data?.data;
//         const userData = res.data.data;
//         setProfile({
//           firstName: userData?.firstName || "Nil",
//           lastName: userData?.lastName || "Nil",
//           email: userData?.email || "Nil",
//           mobile: userData?.mobile || "Nil",
//           dateOfBirth: userData?.dateOfBirth || null,
//           gender: userData?.gender || "Nil",
//           createdAt: userData?.createdAt || null,
//           preferredBillingCycle: userData?.preferredBillingCycle || "Nil",
//           subPlan:
//             userSubscriptionHistory?.active_subscription?.subscriptionName ||
//             "Basic",
//           profileImage: userData?.profileImage || null,
//           isPrimaryUser: userData?.isPrimaryUser || null,
//           linkedUser: userData?.linkedUser || null,
//           cardNumber: userData?.rosettaCardNumber || "Nil",
//         });

//         // setMembershipHistory(
//         //   userSubscriptionHistory?.all_subscriptions.map((item) => ({
//         //     name: item?.subscriptionName || "_",
//         //     duration: item?.numberOfMonths || "_",
//         //     renewalDate: item?.numberOfMonths || "_",
//         //     paymentID: item?.numberOfMonths || "_",
//         //     expiryDate: item?.subscriptionExpiresAt
//         //       ? moment(item.subscriptionExpiresAt).format("DD/MM/YYYY")
//         //       : "_",
//         //     orderAmount: item?.subscriptionName || "_",
//         //     id: item?.id || "_",
//         //   }))
//         // );
//         // setPurchaseHistory([
//         //   ...concessionHistoryCustomer,
//         //   ...concessionHistoryGuest,
//         //   ...ticketHistoryCustomer,
//         //   ...ticketHistoryGuest,
//         // ]);
//       })
//       .catch((err) => console.log(err));
//   };

//   const fetchUserHistory = async () => {
//     const res = await Promise.all([
//       api.getUserConcessionsHistory(userID, `Bearer ${user.jwt}`),
//       api.getUserTicketsHistory(userID, `Bearer ${user.jwt}`),
//       api.getUserSubscriptionsHistory(userID, `Bearer ${user.jwt}`),
//     ]);

//     const concessionHistory = res[0]?.data?.data?.map((item) => ({
//       name: item?.product_name || "_",
//       type: "Concessions",
//       quantity: item?.quantity || "_",
//       paymentID: item?.purchaseId || "_",
//       date: item?.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : "_",
//       orderAmount:
//         item?.quantity && item?.price ? item?.quantity * item?.price : "_",
//       id: item?.id || "",
//     }));
//     const ticketHistory = res[1]?.data?.data?.map((item) => ({
//       name: item?.product_name || "_",
//       type: "Tickets",
//       quantity: item?.quantity || "_",
//       paymentID: item?.purchaseId || "_",
//       date: item?.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : "_",
//       orderAmount:
//         item?.quantity && item?.price ? item?.quantity * item?.price : "_",
//       id: item?.id || "",
//     }));
//     const membershipHistory = res[2]?.data?.data?.map((item) => ({
//       name: item?.subscriptionName || "_",
//       duration: item?.numberOfMonths || "_",
//       renewalDate: !item?.isRenewal
//         ? "NIL"
//         : item?.subscriptionExpiresAt
//         ? moment(item.subscriptionExpiresAt).format("DD/MM/YYYY")
//         : "_",
//       isMultiUserPlan: item?.isMultiUserPlan ? "Yes" : "No",
//       expiryDate: item?.subscriptionExpiresAt
//         ? moment(item.subscriptionExpiresAt).format("DD/MM/YYYY")
//         : "_",
//       orderAmount: item?.subscriptionPlan?.amount || "_",
//       id: item?.id || "_",
//     }));
//     setMembershipHistory(membershipHistory);
//     setPurchaseHistory([...concessionHistory, ...ticketHistory]);
//   };

//   useEffect(() => {
//     fetchUser();
//     fetchUserHistory();
//   }, [userID, user.jwt]);

//   const deleteUserCard = () => {
//     setIsDeleting(true);
//     api
//       .deleteUserCard(userID, `Bearer ${user.jwt}`)
//       .then(() => {
//         setIsDeleting(false);
//         setShowDelete(false);
//         dispatch({
//           type: ALERT_OPEN,
//           payload: {
//             severity: "success",
//             message: "Customer's card has been deleted",
//           },
//         });
//         fetchUser();
//         // this.props.history.push("/dashboard/users");
//       })
//       .catch((err) => {
//         setIsDeleting(false);
//         dispatch({
//           type: ALERT_OPEN,
//           payload: {
//             severity: "error",
//             message: getRequestError(err),
//           },
//         });
//       });
//   };

//   const handleUploadPhoto = (e) => {
//     e.preventDefault();
//     setIsPhotoUpdating(true);
//     setTimeout(() => {
//       setIsPhotoUpdating(false);
//     }, 1000);

//     const payload = new FormData();
//     payload.append("file", e.target.files[0]);

//     api
//       .handleUploadPhoto(payload, userID, `Bearer ${user.jwt}`)
//       .then((res) => {
//         dispatch({
//           type: ALERT_OPEN,
//           payload: {
//             severity: "success",
//             message: "Uploaded image",
//           },
//         });
//         setIsPhotoUpdating(false);
//         refreshList();
//       })
//       .catch((err) => {
//         setIsPhotoUpdating(false);
//         dispatch({
//           type: ALERT_OPEN,
//           payload: {
//             severity: "error",
//             message: getRequestError(err),
//           },
//         });
//         return err;
//       });
//   };

//   const clearUserToken = () => {
//     setIsClearingToken(true);
//     api
//       .clearUserToken(`Bearer ${user.jwt}`, userID)
//       .then(() => {
//         setIsClearingToken(false);
//         dispatch({
//           type: ALERT_OPEN,
//           payload: {
//             severity: "success",
//             message: "Token has been cleared",
//           },
//         });
//       })
//       .catch((err) => {
//         setIsClearingToken(false);
//         dispatch({
//           type: ALERT_OPEN,
//           payload: {
//             severity: "error",
//             message: getRequestError(err),
//           },
//         });
//         return err;
//       });
//   };

//   const refreshList = () => {
//     setPageLoading(true);
//     fetchUser();
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setShowPanel(open);
//   };

//   let activeTabContent = null;

//   if (activeTab === 0) {
//     activeTabContent =
//       purchaseHistory && purchaseHistory.length > 0 ? (
//         <DataGrid
//           rows={purchaseHistory}
//           columns={purchaseColumns}
//           pageSize={15}
//           rowHeight={50}
//           headerHeight={60}
//           disableColumnMenu={true}
//           autoHeight={true}
//         />
//       ) : (
//         <p>No purchases made yet</p>
//       );
//   } else if (activeTab === 1) {
//     activeTabContent =
//       membershipHistory && membershipHistory.length > 0 ? (
//         <DataGrid
//           rows={membershipHistory}
//           columns={membershipHistoryColumns}
//           pageSize={15}
//           rowHeight={50}
//           headerHeight={60}
//           disableColumnMenu={true}
//           autoHeight={true}
//         />
//       ) : (
//         <p>No purchases made yet</p>
//       );
//   } else if (activeTab === 2) {
//     activeTabContent =
//       purchaseHistory && purchaseHistory.length > 0 ? (
//         <DataGrid
//           rows={purchaseHistory}
//           columns={purchaseColumns}
//           pageSize={15}
//           rowHeight={50}
//           headerHeight={60}
//           disableColumnMenu={true}
//           autoHeight={true}
//         />
//       ) : (
//         <p>No purchases made yet</p>
//       );
//   }

//   return pageLoading ? (
//     <div>
//       <CircularLoad show={true} />
//     </div>
//   ) : (
//     <>
//       <div className={backBtn}>
//         <GoBack link="/dashboard/users" />
//       </div>
//       <div className={sectionContainer}>
//         <Section>
//           <div className={section}>
//             <Grid container alignItems="center" justify="space-between">
//               <Grid item>
//                 <Grid container justify="center" direction="column">
//                   <h2 className={profileHeading}>Profile</h2>
//                   <p className={profileTagline}>
//                     View users photo and profile details.
//                   </p>
//                 </Grid>
//               </Grid>
//               <Button
//                 onClick={toggleDrawer(true)}
//                 variant="contained"
//                 color="primary"
//               >
//                 EDIT PROFILE
//               </Button>
//             </Grid>
//             <div className={profileHead}>
//               <div className={avatarSection}>
//                 <Avatar src={profile && profile.profileImage} />
//                 {/* <div style={{ marginBottom: "10px" }} /> */}
//                 <div className={uploadBtn}>
//                   <span className="btn-file">
//                     {isPhotoUpdating ? (
//                       <CircularProgress size={24} />
//                     ) : (
//                       <Camera />
//                     )}
//                     <input
//                       type="file"
//                       className="custom-file-input"
//                       onChange={handleUploadPhoto}
//                       disabled={isPhotoUpdating}
//                       accept="image/x-png,image/gif,image/jpeg,image/jpg"
//                     />
//                   </span>
//                 </div>
//               </div>
//               <div className={userName}>
//                 <Typography variant="h2" className={userNameTypo}>
//                   {profile && profile.firstName + " " + profile.lastName}
//                 </Typography>
//               </div>
//             </div>
//             <Grid
//               className={profileContent}
//               spacing={10}
//               container
//               alignItems="center"
//             >
//               <Grid xs={6} item>
//                 <h3 className={profileTitle}>Email</h3>
//                 <p className={profileValue}>{profile && profile.email}</p>
//               </Grid>
//               <Grid xs={6} item>
//                 <h3 className={profileTitle}>Phone Number</h3>
//                 <p className={profileValue}>{profile && profile.mobile}</p>
//               </Grid>
//               <div className={profileContentDivider} />
//             </Grid>
//             <Grid
//               className={profileContent}
//               spacing={10}
//               container
//               alignItems="center"
//             >
//               <Grid xs={6} item>
//                 <h3 className={profileTitle}>Gender</h3>
//                 <p className={profileValue}>{profile && profile.gender}</p>
//               </Grid>
//               <Grid xs={6} item>
//                 <h3 className={profileTitle}>Date of birth</h3>
//                 <p className={profileValue}>
//                   {profile?.dateOfBirth
//                     ? moment(profile.dateOfBirth).format("DD/MM/YYYY")
//                     : "Nil"}
//                 </p>
//               </Grid>
//               <div className={profileContentDivider} />
//             </Grid>
//             <Grid
//               className={profileContent}
//               spacing={10}
//               container
//               alignItems="center"
//             >
//               {/* <Grid xs={6} item>
//                 <h3 className={profileTitle}>Subscription Plan</h3>
//                 <p className={profileValue}>
//                   {profile && profile.subPlan}
//                   {!profile?.linkedUser ? null : (
//                     <span className={profileContentPill}>
//                       Second user: {profile?.linkedUser}
//                     </span>
//                   )}
//                 </p>
//               </Grid> */}
//               <Grid xs={6} item>
//                 <h3 className={profileTitle}>Card Number</h3>
//                 <p className={profileValue}>{profile && profile?.cardNumber}</p>
//               </Grid>
//               <div className={profileContentDivider} />
//             </Grid>
//             <Grid
//               className={profileContent}
//               spacing={10}
//               container
//               alignItems="center"
//             >
//               {/* <Grid xs={6} item>
//                 <h3 className={profileTitle}>Date Created</h3>
//                 <p className={profileValue}>
//                   {moment(profile && profile.createdAt).format("DD/MM/YYYY")}
//                 </p>
//               </Grid> */}
//               {/* <Grid xs={6} item>
//                 <h3 className={profileTitle}>Subscription Date</h3>
//                 <p className={profileValue}>
//                   {moment(profile && profile.createdAt).format("DD/MM/YYYY")}
//                 </p>
//               </Grid> */}
//               <div className={profileContentDivider} />
//             </Grid>
//             {/* <Grid
//               className={profileContentButtonContainer}
//               spacing={10}
//               container
//               alignItems="center"
//             >
//               {user.details.userType.toLowerCase() === "superadmin" ? (
//                 <Grid item>
//                   <Button
//                     disabled={isRenewingSubscription}
//                     color="primary"
//                     variant="contained"
//                     onClick={() => {
//                       setIsRenewingSubscription(true);
//                       api
//                         .renewUserSubscription(userID, `Bearer ${user.jwt}`)
//                         .then(res => {
//                           setIsRenewingSubscription(false);
//                           dispatch({
//                             type: ALERT_OPEN,
//                             payload: {
//                               severity: "success",
//                               message:
//                                 "Subscription renewal initiated successfully"
//                             }
//                           });
//                         })
//                         .catch(e => {
//                           dispatch({
//                             type: ALERT_OPEN,
//                             payload: {
//                               severity: "error",
//                               message: getRequestError(e)
//                             }
//                           });
//                           setIsRenewingSubscription(false);
//                         });
//                     }}
//                   >
//                     RENEW SUBSCRIPTION
//                   </Button>
//                 </Grid>
//               ) : null}

//               <Grid item>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   onClick={!isClearingToken && clearUserToken}
//                 >
//                   {isClearingToken ? "Clearing Token..." : "Clear Token"}
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   color="secondary"
//                   variant="contained"
//                   onClick={() => setShowDelete(true)}
//                 >
//                   Delete card
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   color="primary"
//                   variant="outlined"
//                   disabled={IsResetPassword}
//                   onClick={() => {
//                     setIsResetPassword(true);
//                     api
//                       .resetPasswordInitUser(profile.email)
//                       .then(res => {
//                         dispatch({
//                           type: ALERT_OPEN,
//                           payload: {
//                             severity: "success",
//                             message: "Email sent!"
//                           }
//                         });
//                       })
//                       .catch(err => {
//                         dispatch({
//                           type: ALERT_OPEN,
//                           payload: {
//                             severity: "error",
//                             message: getRequestError(err)
//                           }
//                         });
//                       })
//                       .finally(() => setIsResetPassword(false));
//                   }}
//                 >
//                   Reset password
//                 </Button>
//               </Grid>
//             </Grid> */}
//           </div>
//         </Section>
//       </div>
//       <div className={tab}>
//         <div className={tabHeader}>
//           <button
//             onClick={() => setActiveTab(0)}
//             className={activeTab === 0 ? tabHeaderItemActive : tabHeaderItem}
//           >
//             Purchase History
//           </button>
//           <button
//             onClick={() => setActiveTab(1)}
//             className={activeTab === 1 ? tabHeaderItemActive : tabHeaderItem}
//           >
//             Subscription History
//           </button>
//           {/* <button
//             onClick={() => setActiveTab(2)}
//             className={activeTab === 2 ? tabHeaderItemActive : tabHeaderItem}
//           >
//             Loyalty Transaction History
//           </button> */}
//         </div>
//         <div className={sectionContainer}>
//           <Section
//             title={
//               activeTab === 0
//                 ? "Purchase History"
//                 : activeTab === 1
//                 ? "Subscription History"
//                 : "Loyalty Transaction History"
//             }
//             titleStyle={titleStyle}
//           >
//             <div className={section}>
//               <div style={{ width: "100%", marginTop: "20px" }}>
//                 {activeTabContent}
//               </div>
//             </div>
//           </Section>
//         </div>
//       </div>
//       <UserSlideIn
//         open={showPanel}
//         close={() => setShowPanel(false)}
//         onClose={() => toggleDrawer(false)}
//         jwt={user.jwt}
//         refreshList={refreshList}
//         profile={{
//           firstName: profile.firstName,
//           lastName: profile.lastName,
//           email: profile.email,
//           mobile: profile.mobile,
//           dateOfBirth: profile.dateOfBirth,
//         }}
//         formTitle="Edit Profile"
//         type="edit"
//         userID={userID}
//       />

//       <DeleteDialog
//         open={showDelete}
//         handleClose={() => setShowDelete(false)}
//         handleDelete={deleteUserCard}
//         loading={isDeleting}
//         title="Are you sure you want to delete this customer's card?"
//       />
//     </>
//   );
// };

// const mapStateToProps = (state) => ({
//   user: state.user.user,
// });

// export default connect(mapStateToProps)(withRouter(UserProfilePage));

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Section from "../../../components/Section";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Point from "../../../components/Section/Point";
import api from "../../../api/users-api";
import Avatar from "../../../components/Avatar";
import useStyles from "./styles";
import moment from "moment";
import GoBack from "../../../components/Button/GoBack";
import { purchaseColumns } from "./purchaseHeadTable";
import { CircularLoad } from "../../../components/Loader";
import UserSlideIn from "../../../containers/UserList/UserSlideIn";
import { DataGrid } from "@material-ui/data-grid";
import { membershipHistoryColumns } from "./membershipHistoryTable";
import DeleteDialog from "../../../components/DeleteDialog";
import Camera from "../../../vectors/Camera";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";

const UserProfilePage = ({ user, match }) => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    dateOfBirth: null,
    gender: "",
    createdAt: null,
    preferredBillingCycle: "",
    subPlan: "",
    profileImage: null,
    cardNumber: ""
  });
  const [pageLoading, setPageLoading] = useState(true);
  const [membershipHistory, setMembershipHistory] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [isPhotoUpdating, setIsPhotoUpdating] = useState(false);
  const [isClearingToken, setIsClearingToken] = useState(false);
  const [isRenewingSubscription, setIsRenewingSubscription] = useState(false);
  const [IsResetPassword, setIsResetPassword] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();

  const { ALERT_OPEN } = types;
  //
  const userID = match.params.id;
  const {
    backBtn,
    sectionContainer,
    titleStyle,
    section,
    profileHead,
    userName,
    userNameTypo,
    avatarSection,
    uploadBtn,
    profileTitle,
    profileValue,
    profileContent,
    profileContentDivider,
    profileContentButtonContainer,
    profileHeading,
    profileTagline
  } = useStyles();

  const fetchUser = () => {
    api
      .getUserInfo(userID, `Bearer ${user.jwt}`)
      .then(res => {
        setPageLoading(false);
        const {
          userData = {},
          userSubscriptionHistory = {},
          userCompleteTransaction = {}
        } = res.data.data;
        setProfile({
          firstName: userData?.firstName || "Nil",
          lastName: userData?.lastName || "Nil",
          email: userData?.email || "Nil",
          mobile: userData?.mobile || "Nil",
          dateOfBirth: userData?.dateOfBirth || null,
          gender: userData?.gender || "Nil",
          createdAt: userData?.createdAt || null,
          preferredBillingCycle: userData?.preferredBillingCycle || "Nil",
          subPlan:
            userSubscriptionHistory?.active_subscription?.subscriptionName ||
            "Basic",
          profileImage: userData?.profileImage || null,
          cardNumber: userData?.rosettaCardNumber || "Nil"
        });

        setMembershipHistory(
          userSubscriptionHistory?.all_subscriptions.map(item => ({
            name: item?.subscriptionName || "_",
            duration: item?.numberOfMonths || "_",
            renewalDate: item?.numberOfMonths || "_",
            paymentID: item?.numberOfMonths || "_",
            expiryDate: item?.subscriptionExpiresAt
              ? moment(item.subscriptionExpiresAt).format("DD/MM/YYYY")
              : "_",
            orderAmount: item?.subscriptionName || "_",
            id: item?.id || "_"
          }))
        );
        const concessionHistoryGuest = userCompleteTransaction?.guest_purchase?.concession?.map(
          item => ({
            name: item?.product_name || "_",
            type: "Concessions",
            quantity: item?.quantity || "_",
            paymentID: item?.paymentReference || "_",
            date: item?.createdAt
              ? moment(item.createdAt).format("DD/MM/YYYY")
              : "_",
            orderAmount:
              item?.quantity && item?.price
                ? item?.quantity * item?.price
                : "_",
            id: item?.id || ""
          })
        );
        const concessionHistoryCustomer = userCompleteTransaction?.customer_purchase?.concession?.map(
          item => ({
            name: item?.product_name || "_",
            type: "Concessions",
            quantity: item?.quantity || "_",
            paymentID: item?.paymentReference || "_",
            date: item?.createdAt
              ? moment(item.createdAt).format("DD/MM/YYYY")
              : "_",
            orderAmount:
              item?.quantity && item?.price
                ? item?.quantity * item?.price
                : "_",
            id: item?.id || ""
          })
        );
        const ticketHistoryCustomer = userCompleteTransaction?.customer_purchase?.ticket?.map(
          item => ({
            name: item?.product_name || "_",
            type: "Tickets",
            quantity: item?.quantity || "_",
            paymentID: item?.transaction || "_",
            date: item?.createdAt
              ? moment(item.createdAt).format("DD/MM/YYYY")
              : "_",
            orderAmount:
              item?.quantity && item?.price
                ? item?.quantity * item?.price
                : "_",
            id: item?.id || ""
          })
        );
        const ticketHistoryGuest = userCompleteTransaction?.guest_purchase?.ticket?.map(
          item => ({
            name: item?.product_name || "_",
            type: "Tickets",
            quantity: item?.quantity || "_",
            paymentID: item?.transaction || "_",
            date: item?.createdAt
              ? moment(item.createdAt).format("DD/MM/YYYY")
              : "_",
            orderAmount:
              item?.quantity && (item?.price || item?.price === 0)
                ? item?.quantity * item?.price
                : "_",
            id: item?.id || ""
          })
        );
        setPurchaseHistory([
          ...concessionHistoryCustomer,
          ...concessionHistoryGuest,
          ...ticketHistoryCustomer,
          ...ticketHistoryGuest
        ]);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchUser();
  }, [userID, user.jwt]);

  const deleteUserCard = () => {
    setIsDeleting(true);
    api
      .deleteUserCard(userID, `Bearer ${user.jwt}`)
      .then(() => {
        setIsDeleting(false);
        setShowDelete(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: "Customer's card has been deleted"
          }
        });
        fetchUser();
        // this.props.history.push("/dashboard/users");
      })
      .catch(err => {
        setIsDeleting(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      });
  };

  const handleUploadPhoto = e => {
    e.preventDefault();
    setIsPhotoUpdating(true);
    setTimeout(() => {
      setIsPhotoUpdating(false);
    }, 1000);

    const payload = new FormData();
    payload.append("file", e.target.files[0]);

    api
      .handleUploadPhoto(payload, userID, `Bearer ${user.jwt}`)
      .then(res => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: "Uploaded image"
          }
        });
        setIsPhotoUpdating(false);
        refreshList();
      })
      .catch(err => {
        setIsPhotoUpdating(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const clearUserToken = () => {
    setIsClearingToken(true);
    api
      .clearUserToken(`Bearer ${user.jwt}`, userID)
      .then(() => {
        setIsClearingToken(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: "Token has been cleared"
          }
        });
      })
      .catch(err => {
        setIsClearingToken(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const refreshList = () => {
    setPageLoading(true);
    fetchUser();
  };

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowPanel(open);
  };

  return pageLoading ? (
    <div>
      <CircularLoad show={true} />
    </div>
  ) : (
    <>
      <div className={backBtn}>
        <GoBack link="/dashboard/users" />
      </div>
      <div className={sectionContainer}>
        <Section>
          <div className={section}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container justify="center" direction="column">
                  <h2 className={profileHeading}>Profile</h2>
                  <p className={profileTagline}>
                    View users photo and profile details.
                  </p>
                </Grid>
              </Grid>
              <Button
                onClick={toggleDrawer(true)}
                variant="contained"
                color="primary"
              >
                EDIT PROFILE
              </Button>
            </Grid>
            <div className={profileHead}>
              <div className={avatarSection}>
                <Avatar src={profile && profile.profileImage} />
                {/* <div style={{ marginBottom: "10px" }} /> */}
                <div className={uploadBtn}>
                  <span className="btn-file">
                    {isPhotoUpdating ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Camera />
                    )}
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={handleUploadPhoto}
                      disabled={isPhotoUpdating}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                </div>
              </div>
              <div className={userName}>
                <Typography variant="h2" className={userNameTypo}>
                  {profile && profile.firstName + " " + profile.lastName}
                </Typography>
              </div>
            </div>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Email</h3>
                <p className={profileValue}>{profile && profile.email}</p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Phone Number</h3>
                <p className={profileValue}>{profile && profile.mobile}</p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Gender</h3>
                <p className={profileValue}>{profile && profile.gender}</p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Date of birth</h3>
                <p className={profileValue}>
                  {profile?.dateOfBirth
                    ? moment(profile.dateOfBirth).format("DD/MM/YYYY")
                    : "Nil"}
                </p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Subscription Plan</h3>
                <p className={profileValue}>{profile && profile.subPlan}</p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Card Number</h3>
                <p className={profileValue}>{profile && profile.cardNumber}</p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Date Created</h3>
                <p className={profileValue}>
                  {moment(profile && profile.createdAt).format("DD/MM/YYYY")}
                </p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Preferred Billing Cycle</h3>
                <p className={profileValue}>
                  {profile && profile.preferredBillingCycle}
                </p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
            <Grid
              className={profileContentButtonContainer}
              spacing={10}
              container
              alignItems="center"
            >
              {user.details.userType.toLowerCase() === "superadmin" ? (
                <Grid item>
                  <Button
                    disabled={isRenewingSubscription}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setIsRenewingSubscription(true);
                      api
                        .renewUserSubscription(userID, `Bearer ${user.jwt}`)
                        .then(res => {
                          setIsRenewingSubscription(false);
                          dispatch({
                            type: ALERT_OPEN,
                            payload: {
                              severity: "success",
                              message:
                                "Subscription renewal initiated successfully"
                            }
                          });
                        })
                        .catch(e => {
                          dispatch({
                            type: ALERT_OPEN,
                            payload: {
                              severity: "error",
                              message: getRequestError(e)
                            }
                          });
                          setIsRenewingSubscription(false);
                        });
                    }}
                  >
                    RENEW SUBSCRIPTION
                  </Button>
                </Grid>
              ) : null}

              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={!isClearingToken && clearUserToken}
                >
                  {isClearingToken ? "Clearing Token..." : "Clear Token"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setShowDelete(true)}
                >
                  Delete card
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={IsResetPassword}
                  onClick={() => {
                    setIsResetPassword(true);
                    api
                      .resetPasswordInitUser(profile.email)
                      .then(res => {
                        dispatch({
                          type: ALERT_OPEN,
                          payload: {
                            severity: "success",
                            message: "Email sent!"
                          }
                        });
                      })
                      .catch(err => {
                        dispatch({
                          type: ALERT_OPEN,
                          payload: {
                            severity: "error",
                            message: getRequestError(err)
                          }
                        });
                      })
                      .finally(() => setIsResetPassword(false));
                  }}
                >
                  Reset password
                </Button>
              </Grid>
            </Grid>
          </div>
        </Section>
      </div>
      <div className={sectionContainer}>
        <Section
          title="Activities in the last 6 months"
          titleStyle={titleStyle}
        >
          <div className={section}>
            <Point
              title="Purchases"
              contain
              component={
                <div style={{ width: "100%", marginTop: "20px" }}>
                  {purchaseHistory && purchaseHistory.length > 0 ? (
                    <DataGrid
                      rows={purchaseHistory}
                      columns={purchaseColumns}
                      pageSize={15}
                      rowHeight={50}
                      headerHeight={60}
                      disableColumnMenu={true}
                      autoHeight={true}
                    />
                  ) : (
                    <p>No purchases made yet</p>
                  )}
                </div>
              }
            />
          </div>
        </Section>
      </div>
      <div className={sectionContainer}>
        <Section title="Membership History" titleStyle={titleStyle}>
          <div className={section}>
            <div style={{ width: "100%", marginTop: "20px" }}>
              {membershipHistory && membershipHistory.length > 0 ? (
                <DataGrid
                  rows={membershipHistory}
                  columns={membershipHistoryColumns}
                  pageSize={15}
                  rowHeight={50}
                  headerHeight={60}
                  disableColumnMenu={true}
                  autoHeight={true}
                />
              ) : (
                <p>No purchases made yet</p>
              )}
            </div>
            {/* }
            /> */}
          </div>
        </Section>
      </div>
      <UserSlideIn
        open={showPanel}
        close={() => setShowPanel(false)}
        onClose={() => toggleDrawer(false)}
        jwt={user.jwt}
        refreshList={refreshList}
        profile={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          mobile: profile.mobile,
          dateOfBirth: profile.dateOfBirth
        }}
        formTitle="Edit Profile"
        type="edit"
        userID={userID}
      />

      <DeleteDialog
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteUserCard}
        loading={isDeleting}
        title="Are you sure you want to delete this customer's card?"
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});

export default connect(mapStateToProps)(withRouter(UserProfilePage));
