import api from "./instance";

export default {
  fetchConcessions: (token, cinema) =>
    api
      .get(`/concessions/${cinema}?add_mobile_device=true`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  fetchRosettaConcessions: (token, cinema) =>
    api
      .get(`/concessions/rosetta/${cinema}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  uploadConcessionImage: (body, token) =>
    api
      .post(`/concessions/upload-image`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editComboDeal: (body, id, token) =>
    api
      .put(`/concessions/combo-deals/${id}`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  createComboDeal: (body, token) =>
    api
      .post(`/concessions/combo-deals`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  deleteComboDeal: (token, id) =>
    api
      .delete(`/concessions/combo-deals/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  deleteComboDealItems: (token, ids) =>
    api
      .delete(`/concessions/combo-deals/items/${ids}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  activate: (token, id) =>
    api
      .put(
        `/concessions/activate/${id}`,
        {},
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  deactivate: (token, id, cinema_id) =>
    api
      .put(
        `/concessions/deactivate`,
        { rosetta_product_ids: [id], cinema_id },
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res)
};

// import api from "api";

// export default {
//   fetchConcessions: (token, cinema) =>
//     api
//       .get(
//         `/concessions/${cinema}?add_mobile_device=true&customer_card_number=null`,
//         {
//           headers: { Authorization: token }
//         }
//       )
//       .then(res => res),
//   fetchRosettaConcessions: (token, cinema) =>
//     api
//       .get(`/concessions/rosetta/${cinema}`, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   uploadConcessionImage: (body, token) =>
//     api
//       .post(`/concessions/upload-image`, body, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   editComboDeal: (body, id, token) =>
//     api
//       .put(`/concessions/combo-deals/${id}`, body, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   createComboDeal: (body, token) =>
//     api
//       .post(`/concessions/combo-deals`, body, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   deleteComboDeal: (token, id) =>
//     api
//       .delete(`/concessions/combo-deals/${id}`, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   deleteComboDealItems: (token, ids) =>
//     api
//       .delete(`/concessions/combo-deals/items/${ids}`, {
//         headers: { Authorization: token }
//       })
//       .then(res => res),
//   activate: (token, id) =>
//     api
//       .put(
//         `/concessions/activate/${id}`,
//         {},
//         {
//           headers: { Authorization: token }
//         }
//       )
//       .then(res => res),
//   deactivate: (token, id, cinema_id) =>
//     api
//       .put(
//         `/concessions/deactivate`,
//         { rosetta_product_ids: [id], cinema_id },
//         {
//           headers: { Authorization: token }
//         }
//       )
//       .then(res => res)
// };
