import api from "./instance";

export default {
  getUsersActivityBreakdown: (token, startDate, endDate) =>
    api
      .get(
        `/analytics/analytics/users?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getSalesBreakdown: (token, startDate, endDate) =>
    api
      .get(
        `/analytics/sales-and-revenue-breakdown?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getChurnRate: (token, startDate, endDate) =>
    api
      .get(
        `/analytics/churn-rate?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getRedemptionRate: (token, startDate, endDate) =>
    api
      .get(
        `/analytics/redemption-rate?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getUsers: (token, startDate, endDate) =>
    api
      .get(
        `/analytics/analytics/users?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  // not in use
  getNoOfTicketsSold: (token, year) =>
    api
      .get(`/analytics/number-of-sold-tickets?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getTicketsSoldCount: (token, month, year) =>
    api
      .get(`/analytics/ticket-count?year=${year}&monthIndex=${month}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getTicketsRevenue: (token, month, year) =>
    api
      .get(`/analytics/ticket-revenue?year=${year}&monthIndex=${month}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getRevenue: (token, year) =>
    api
      .get(`/analytics/monthly-recurring-revenue?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getUsersStats: (token, year) =>
    api
      .get(`/analytics/new-signups-analytics?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getRedemptionRateTwo: (token, year) =>
    api
      .get(`/subscriptions/redemption-rate?date=${year}-01-01`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getChurnMRR: (token, year) =>
    api
      .get(`/analytics/cancelled-recurring-revenue?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getConcessionDiscount: (token, year) =>
    api
      .get(`/analytics/subscribed-concession-purchase?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getStats: token =>
    api
      .get("/analytics/dashboard", {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getOneTimePurchase: token =>
    api
      .get("/analytics/one-time-purchase", {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getUsersVisit: (token, month, year) =>
    api
      .get(`/analytics/get-users-visit?date=${year}-${month}-01`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getSubscriptionConversionRate: (token, startDate, endDate) =>
    api
      .get(
        `/subscriptions/conversion-rate?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getAverageNoOfRedeemedTickets: (token, date, weekly) =>
    api
      .get(
        `/analytics/average-ticket-redemption?date=${date}&duration=${weekly}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getCustomerChurnRate: (token, year) =>
    api
      .get(`/analytics/customer-churn-rate?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res)
};

// import api from "api";

// export default {
//   getSalesBreakdown: (token, startDate, endDate) =>
//     api
//       .get(
//         `/purchase_history/sales-and-revenue-breakdown?end_date=${endDate}&start_date=${startDate}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   getChurnRate: (token, startDate, endDate) =>
//     api
//       .get(
//         `/admin/analytics/churn-rate?end_date=${endDate}&start_date=${startDate}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   getRedemptionRate: (token, startDate, endDate) =>
//     api
//       .get(
//         `/purchase_history/analytics/redemption-rate?end_date=${endDate}&start_date=${startDate}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   getUsers: (token, startDate, endDate) =>
//     api
//       .get(
//         `/admin/analytics/users?end_date=${endDate}&start_date=${startDate}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   // not in use
//   getNoOfTicketsSold: (token, year) =>
//     api
//       .get(`/purchase_history/number-of-sold-tickets?year=${year}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getTicketsSoldCount: (token, month, year) =>
//     api
//       .get(`/purchase_history/ticket-count?year=${year}&monthIndex=${month}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getTicketsRevenue: (token, month, year) =>
//     api
//       .get(
//         `/purchase_history/ticket-revenue?year=${year}&monthIndex=${month}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   getRevenue: (token, year) =>
//     api
//       .get(`/admin/monthly-recurring-revenue?year=${year}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getUsersStats: (token, year) =>
//     api
//       .get(`/admin/new-signups-analytics?year=${year}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getRedemptionRateTwo: (token, year) =>
//     api
//       .get(`/subscriptions/redemption-rate?date=${year}-01-01`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getChurnMRR: (token, year) =>
//     api
//       .get(`/admin/cancelled-recurring-revenue?year=${year}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getConcessionDiscount: (token, year) =>
//     api
//       .get(`/purchase_history/subscribed-concession-purchase?year=${year}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getStats: token =>
//     api
//       .get("/admin/dashboard", {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getOneTimePurchase: token =>
//     api
//       .get("/purchase_history/one-time-purchase", {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getUsersVisit: (token, month, year) =>
//     api
//       .get(`/admin/get-users-visit?date=${year}-${month}-01`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res),
//   getSubscriptionConversionRate: (token, startDate, endDate) =>
//     api
//       .get(
//         `/subscriptions/conversion-rate?start_date=${startDate}&end_date=${endDate}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   getAverageNoOfRedeemedTickets: (token, date, weekly) =>
//     api
//       .get(
//         `/purchase_history/average-ticket-redemption?date=${date}&duration=${weekly}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       )
//       .then(res => res),
//   getCustomerChurnRate: (token, year) =>
//     api
//       .get(`/admin/customer-churn-rate?year=${year}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//       .then(res => res)
// };
