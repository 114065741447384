import React, { useState, useEffect } from "react";
import api from "../../../api/new-promotions";
import useStyles from "./styles";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import "./promotions.css";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import { Delete, Edit } from "@material-ui/icons";
import DeletePromotion from "./DeletePromotion";
import Info from "../../../vectors/Info";
import Switch from "../../../components/Switch/Switch";
import ManagePromotion from "./ManagePromotion";
import { useHistory } from "react-router-dom";
import InfoBlue from "../../../vectors/info-blue";

const HeroBanner = ({ user }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [maxLimit, setMaxLimit] = useState(0);
  const [selectedPromotionId, setSelectedPromotionId] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isVisibleOnHomepageLoading, setIsVisibleOnHomepageLoading] = useState(
    false
  );

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const isLimitReached =
    filtered.filter(item => item.isVisibleOnHomepage)?.length >= 3;

  const fetchPromotions = _page => {
    setLoading(true);
    api
      .getList(`Bearer ${user.jwt}`, _page + 1, "body")
      .then(res => {
        setMaxLimit(res?.data?.data?.metamaxActivePromotionsOnHomepageBody);
        setFiltered(
          res?.data?.data?.promotions?.map((item, index) => ({
            isActive: item?.isActive,
            // type: item.isMovie ? "Movie" : "Advert",
            title: item?.title ?? "_",
            id: item?.id ?? index,
            webMedia: item?.web_media,
            mobileMedia: item?.mobile_media,
            thumbnailImage: item?.thumbnail,
            // destinationUrl: item?.url ?? "_",
            primaryCTA: item?.primaryCTA ?? "_",
            secondaryCTA: item?.secondaryCTA ?? "_",
            primaryCTAUrl: item?.primaryCTAUrl ?? null,
            secondaryCTAUrl: item?.secondaryCTAUrl ?? null,
            isVisibleOnHomepage: item?.isVisibleOnHomepage
          })) ?? []
        );
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchPromotions(page);
  }, [page]);

  const handleDelete = id => {
    return api
      .deletePromotion(`Bearer ${user.jwt}`, id)
      .then(res => {
        setOpenDelete(false);
        fetchPromotions(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleUpdatingIsHomepageVisible = ({ id, status }) => {
    if (!isActiveLoading) {
      setIsVisibleOnHomepageLoading(true);
      api
        .editPromotion(
          `Bearer ${user.jwt}`,
          {
            isVisibleOnHomepage: status
          },
          id
        )
        .then(res => {
          fetchPromotions(0);
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          return err;
        })
        .finally(() => {
          setIsVisibleOnHomepageLoading(false);
        });
    }
  };

  const handleUpdatingIsActive = ({ id, status }) => {
    if (!isActiveLoading) {
      setIsActiveLoading(true);
      api
        .editPromotionStatus(`Bearer ${user.jwt}`, status, id)
        .then(res => {
          setIsActiveLoading(false);
          fetchPromotions(0);
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          return err;
        });
    }
  };

  const handleOpenDeleteModal = (e, id) => {
    e.preventDefault();
    setSelectedPromotionId(id);
    setOpenDelete(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Promotion",
      width: 360,
      renderCell: params => {
        return (
          <div className={classes.title}>
            {params?.row?.thumbnailImage ? (
              <img
                className={classes.image}
                alt={params.row.title}
                src={params.row.thumbnailImage}
                width={120}
                height={67}
              />
            ) : null}
            <span>{params.row.title}</span>
          </div>
        );
      }
    },
    {
      field: "primaryCTA",
      headerName: "Primary CTA",
      width: 360,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>{params.row.primaryCTA}</span>
            {params.row.primaryCTAUrl && (
              <Tooltip
                classes={{
                  tooltip: classes.tooltip
                }}
                title={params.row.primaryCTAUrl}
                arrow
              >
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex"
                  }}
                >
                  <Info />
                </div>
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      field: "secondaryCTA",
      headerName: "Secondary CTA",
      width: 360,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>{params.row.secondaryCTA}</span>
            {params.row.secondaryCTAUrl && (
              <Tooltip
                classes={{
                  tooltip: classes.tooltip
                }}
                title={params.row.secondaryCTAUrl}
                arrow
              >
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex"
                  }}
                >
                  <Info />
                </div>
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      field: "isVisibleOnHomepage",
      headerName: "View on Landing page",
      width: 200,
      renderCell: params => {
        return (
          <Switch
            defaultChecked={params.row.isVisibleOnHomepage}
            checked={params.row.isVisibleOnHomepage}
            onChange={() => {
              params.row.isVisibleOnHomepage
                ? handleUpdatingIsHomepageVisible({
                    id: params.row.id,
                    status: false
                  })
                : handleUpdatingIsHomepageVisible({
                    id: params.row.id,
                    status: true
                  });
            }}
            disabled={
              params.row.isVisibleOnHomepage
                ? isVisibleOnHomepageLoading
                : isVisibleOnHomepageLoading || isLimitReached
            }
          />
        );
      }
    },
    {
      field: "isActive",
      headerName: "Activate",
      width: 200,
      renderCell: params => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            checked={params.row.isActive}
            onChange={() => {
              params.row.isActive
                ? handleUpdatingIsActive({
                    id: params.row.id,
                    status: false
                  })
                : handleUpdatingIsActive({
                    id: params.row.id,
                    status: true
                  });
            }}
            disabled={isActiveLoading}
          />
        );
      }
    },
    {
      field: "id",
      headerName: " ",
      width: 100,
      renderCell: params => {
        return (
          <div className={classes.actionBtn}>
            <Edit
              color="primary"
              className={classes.editButton}
              onClick={() => {
                history.push(`/dashboard/promotions/${params.row.id}`);
              }}
            />
            <button onClick={e => handleOpenDeleteModal(e, params.row.id)}>
              <Delete color="secondary" />
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <div className={classes.head}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <div className={classes.warningContainer}>
              <InfoBlue />
              <p className={classes.warning}>
                Activate a maximum of 3 Ads & Promotion to be shown on the
                Landing page{" "}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Grid justify="flex-end" container>
              {/* {user.details.userType.toLowerCase() === "superadmin" ? ( */}
              <Button
                onClick={() => {
                  history.push(`/dashboard/promotions/add`);
                }}
                className={classes.addBannerBtn}
                variant="outlined"
                color="primary"
              >
                + ADD PROMOTION{" "}
              </Button>
              {/* ) : null} */}
              <CSVLink data={filtered} filename={"hero-banners.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DeletePromotion
        jwt={user.jwt}
        recordId={selectedPromotionId}
        open={openDelete}
        handleClose={() => handleOpenClose(setOpenDelete, false)}
        handleDelete={handleDelete}
      />
      <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
        <DataGrid
          rows={filtered}
          columns={columns}
          pageSize={10}
          rowHeight={93}
          headerHeight={80}
          disableColumnMenu={true}
          autoHeight={true}
          page={page}
          rowCount={filtered.length}
          onPageChange={page => {
            setPage(page);
          }}
          paginationMode="server"
          loading={loading}
          className="promotions-table"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(HeroBanner);
