import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  head: {
    display: "flex",
    justifyContent: "flex-end"
  },
  dropDowns: {
    display: "flex"
  },
  addBannerBtn: {
    marginRight: "30px"
  },
  title: {
    display: "flex",
    gap: "30px",
    alignItems: "center"
  },
  tooltip: {
    padding: "18px 13px",
    // maxWidth: "222px",
    "box-sizing": "border-box",
    border: "0.85px solid rgba(151,151,151,0.25)",
    transform: "rotate(180deg)",
    "border-radius": "4.58px",
    "background-color": "#16161A",
    color: "rgba(255,255,255,0.6)",
    fontWeight: 500,
    fontSize: "11px",
    letterSpacing: "0.08px",
    lineHeight: "20px"
  },
  ellipsisContainer: {
    position: "relative",
    width: "100%"
  },
  ellipsis: {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // maxWidth: "90%",
    display: "block"
  },
  image: {
    objectFit: "cover"
  },
  actionBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px"
  },
  editButton: {
    cursor: "pointer"
  },
  warningContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px"
  },
  warning: {
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400
  }
}));

export const itemStyles = makeStyles(theme => ({
  container: {
    paddingTop: 21
  }
}));

export default useStyles;
